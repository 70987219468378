import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { PageFragment } from 'graphql/fragments/Page.generated';
import { getSrlPageNumbers } from './SRL.utils';

const PaginationWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: ['space-between', 'flex-end'],

    ul: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',

      li: {
        ml: 2,
      },
    },
  })
);

const PageButton = styled(Button)<{ selected?: boolean }>(
  ({ theme }) => theme.buttons.grayGradientButton,
  sx2CssThemeFn(({ selected }) =>
    selected
      ? {
          background: 'white',
          color: 'secondary',
          '&:hover': { cursor: 'default' },
        }
      : {}
  )
);

const ArrowButton = styled(Button)(
  sx2CssThemeFn({
    p: '5px',
    display: 'flex',
    borderRadius: 'default',
    border: '1px solid',
    borderColor: 'primary',
    '&:hover': {
      backgroundColor: 'textGray',
      borderColor: 'textGray',
    },
    '&:disabled': {
      background: 'linear-gradient(to bottom, #fefefe 0%, #cecece 100%)',
      borderColor: 'borderColor',
      color: 'borderGray',
      cursor: 'default',
    },
  })
);

interface ISrlPaginationProps {
  pageData: PageFragment;
  onPaginate: (nextPage?: number | null) => void;
  containerRef: React.MutableRefObject<HTMLDivElement>;
}

const SrlPagination: React.FC<ISrlPaginationProps> = (
  props: ISrlPaginationProps
) => {
  const { pageData, onPaginate, containerRef } = props;
  const { mobile } = useDeviceType();

  const pageCount = Math.ceil(pageData.resultsTotal / pageData.resultsPerPage);
  const currentPage = pageData.pageNumber + 1;

  const pageNumbers = useMemo(
    () => getSrlPageNumbers(currentPage, pageCount),
    [currentPage, pageCount]
  );

  const scrollToContainerTop = useCallback(() => {
    const offsetTop = containerRef.current?.offsetTop;
    if (typeof offsetTop === 'number') {
      setTimeout(() => {
        window.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }, 50);
    }
  }, [containerRef]);

  const onPageSelected = (selectedPage: number) => {
    if (selectedPage === currentPage) return;
    onPaginate(selectedPage - 1);
    scrollToContainerTop();
  };

  // We don't need pagination in case we have only 1 page
  if (pageCount <= 1) return null;

  const previousButton = (
    <ArrowButton
      icon={{ name: 'chevron-long-left' }}
      disabled={currentPage <= 1}
      onClick={() => {
        if (currentPage > 1) {
          onPageSelected(currentPage - 1);
        }
      }}
    />
  );

  const nextButton = (
    <ArrowButton
      icon={{ name: 'chevron-long-right' }}
      disabled={currentPage >= pageCount}
      onClick={() => {
        if (currentPage < pageCount) {
          onPageSelected(currentPage + 1);
        }
      }}
    />
  );

  return (
    <PaginationWrapper>
      {mobile && previousButton}
      <ul>
        {pageNumbers.map((pageNumber, index) => {
          const isGap = index > 0 && pageNumber - pageNumbers[index - 1] > 1;

          return (
            <React.Fragment key={pageNumber}>
              {isGap && (
                <li>
                  <PageButton disabled>...</PageButton>
                </li>
              )}
              <li>
                <PageButton
                  selected={pageNumber === currentPage}
                  onClick={() => onPageSelected(pageNumber)}
                >
                  {pageNumber}
                </PageButton>
              </li>
            </React.Fragment>
          );
        })}
        {!mobile && <li>{previousButton}</li>}
        {!mobile && <li>{nextButton}</li>}
      </ul>
      {mobile && nextButton}
    </PaginationWrapper>
  );
};

export default SrlPagination;
