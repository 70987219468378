import { useTranslation } from 'next-i18next';
import { Ref } from 'react';
import styled from 'styled-components';
import { TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatPrice } from '@hotelplan/libs.utils';
import { SrlGeoItemFragment } from 'graphql/srl/SrlGeoItem.generated';
import useOfferTypeDescription from './useOfferTypeDescription';

const Wrapper = styled.div(
  sx2CssThemeFn({
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',

    '.name': {
      flexGrow: '1',
      color: 'primary',
      textAlign: 'left',
      fontSize: [1, '18px'],
    },

    '.count': {
      flex: '160px 0 1',
      color: 'primary',
      textAlign: 'right',
      fontSize: '14px',
    },

    '.price': {
      flex: '150px 0 1',
      color: 'secondary',
      textAlign: 'right',
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSize: ['14px', 1],
      fontWeight: 'normal',

      '.amount': {
        fontSize: [2, '26px'],
      },
    },
  })
);

interface ISrlGeoItemTitleProps {
  geoItem: SrlGeoItemFragment;
  travelType: TravelType;
  refCallback?: Ref<HTMLDivElement>;
  className?: string;
}

const SrlGeoItemTitle: React.FC<ISrlGeoItemTitleProps> = (
  props: ISrlGeoItemTitleProps
) => {
  const {
    geoItem: { count, geoObject, perPersonPrice },
    travelType,
    refCallback,
    className,
  } = props;
  const [t] = useTranslation(['common']);
  const { mobile } = useDeviceType();
  const offerTypeDescription = useOfferTypeDescription(travelType, count);

  return (
    <Wrapper ref={refCallback} className={className}>
      <div className="name">
        {geoObject.name + (mobile ? ` (${count})` : '')}
      </div>
      {!mobile && (
        <div className="count">{`${count} ${offerTypeDescription}`}</div>
      )}
      <div className="price">
        {`${t('common:priceFrom')} ${perPersonPrice.currency}`}&nbsp;
        <span className="amount">
          {formatPrice(perPersonPrice, { currency: false })}
        </span>
      </div>
    </Wrapper>
  );
};

export default SrlGeoItemTitle;
