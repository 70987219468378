import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const DesktopWrap = styled.div(
  sx2CssThemeFn({
    p: [null, 2],
    backgroundColor: [null, '#fff'],
  })
);

const SubGeoFiltersSkeleton = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 185, h: 300 }
    : { w: 554, h: 54 };

  return (
    <DesktopWrap>
      <PlaceholderLoader
        width={width}
        height={height}
        uid="subGeoFiltersSkeleton"
        style={{ height: height, width: width, display: 'block' }}
      >
        {mobile ? (
          <>
            <rect width="185" height="1" />
            <rect y="36" width="185" height="1" />
            <rect x="9" y="8" width="167" height="21" rx="8" />
            <rect y="72" width="185" height="1" />
            <rect x="9" y="44" width="167" height="21" rx="8" />
            <rect y="108" width="185" height="1" />
            <rect x="9" y="80" width="167" height="21" rx="8" />
            <rect y="143" width="185" height="1" />
            <rect x="9" y="115" width="167" height="21" rx="8" />
            <rect y="181" width="185" height="1" />
            <rect x="9" y="153" width="167" height="21" rx="8" />
            <rect y="217" width="185" height="1" />
            <rect x="9" y="189" width="167" height="21" rx="8" />
            <rect y="253" width="185" height="1" />
            <rect x="9" y="225" width="167" height="21" rx="8" />
            <rect y="288" width="185" height="1" />
            <rect y="299" width="185" height="1" />
            <rect x="9" y="260" width="167" height="21" rx="8" />
            <rect width="1" height="300" />
            <rect x="184" width="1" height="300" />
          </>
        ) : (
          <>
            <rect y="1" width="13" height="13" rx="3" />
            <rect x="18" y="1" width="62" height="13" />
            <rect x="85" y="22" width="13" height="13" rx="3" />
            <rect x="103" y="22" width="63" height="13" />
            <rect y="22" width="13" height="13" rx="3" />
            <rect x="18" y="22" width="62" height="13" />
            <rect x="474" y="1" width="13" height="13" rx="3" />
            <rect x="492" y="1" width="62" height="13" />
            <rect x="474" y="22" width="13" height="13" rx="3" />
            <rect x="492" y="22" width="62" height="13" />
            <rect x="192" y="1" width="13" height="13" rx="3" />
            <rect x="210" y="1" width="62" height="13" />
            <rect x="106" y="41" width="13" height="13" rx="3" />
            <rect x="124" y="41" width="62" height="13" />
            <rect x="85" y="1" width="13" height="13" rx="3" />
            <rect x="103" y="1" width="83" height="13" />
            <rect y="41" width="13" height="13" rx="3" />
            <rect x="18" y="41" width="83" height="13" />
            <rect x="172" y="22" width="13" height="13" rx="3" />
            <rect x="190" y="22" width="82" height="13" />
            <rect x="280" y="20" width="13" height="14" rx="3" />
            <rect x="298" y="20" width="62" height="14" />
            <rect x="386" width="14" height="13" rx="3" />
            <rect x="404" width="63" height="13" />
            <rect x="280" width="13" height="13" rx="3" />
            <rect x="298" width="82" height="13" />
            <rect x="366" y="20" width="13" height="14" rx="3" />
            <rect x="384" y="20" width="83" height="14" />
          </>
        )}
      </PlaceholderLoader>
    </DesktopWrap>
  );
};

export default SubGeoFiltersSkeleton;
