import { useTranslation } from 'next-i18next';
import React from 'react';
import { WishlistFavContainer } from 'components/domain/wishlist';
import { ProductItemFragment } from 'graphql/srl/SRLProductItem.generated';
import {
  ETrackingOfferPosition,
  trackOfferClick,
} from 'libs/tracking/trackingEvents/trackSelectOffer';
import {
  SRL_SORTING_LABEL_KEYS,
  useSortingResultsContext,
} from './sorting/SortingResultsContext';
import SrlProductItem from './SrlProductItem';

interface ISrlProductsListProps {
  items: ProductItemFragment[];
  page?: number;
  geoObjectId?: string;
}

const SrlProductsList: React.FC<ISrlProductsListProps> = (
  props: ISrlProductsListProps
) => {
  const { items, page, geoObjectId } = props;
  const [t, { language }] = useTranslation('search');
  const { sorting } = useSortingResultsContext();

  return (
    <div>
      {items.map((item, index) => (
        <WishlistFavContainer
          key={item.giata}
          offerId={item.offerId}
          inWishlist={item.inWishlist}
        >
          <SrlProductItem
            {...item}
            page={page}
            geoObjectId={geoObjectId}
            onClick={() => {
              trackOfferClick(language, {
                position: ETrackingOfferPosition.SRL,
                offer_position: index + 1,
                select_offer_page: page + 1,
                sortingLabel: t(SRL_SORTING_LABEL_KEYS[sorting], { lng: 'de' }),
              });
            }}
          />
        </WishlistFavContainer>
      ))}
    </div>
  );
};

export default SrlProductsList;
