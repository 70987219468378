import { useCallback, useMemo, useState } from 'react';
import { SrlSingleResultFragment } from 'graphql/srl/GetSRLResults.generated';
import { useGetSrlSingleQuery } from 'graphql/srl/GetSrlSingle.generated';
import { SrlEmptyResultFragment } from 'graphql/srl/SrlEmptyResult.generated';
import { useSortingResultsContext } from './sorting/SortingResultsContext';
import { useSrlCriteriaValues } from './useSrlDataToCriteria';
import { useSubGeoFiltersContext } from './useSubGeoFiltersContext';

export const useSrlSingleData = (
  initialPage: number,
  initialSubGeoFilters?: string[],
  onFetchMore?: (
    data: SrlSingleResultFragment | SrlEmptyResultFragment
  ) => void,
  skip?: boolean
) => {
  const inputValues = useSrlCriteriaValues();
  const { sorting: sortingValue } = useSortingResultsContext();
  const { subGeoFilterValues } = useSubGeoFiltersContext();

  const [currentPage, setCurrentPage] = useState(initialPage);

  const onPaginate = useCallback(
    (pageNumber: number) => {
      setCurrentPage(pageNumber);
    },
    [setCurrentPage]
  );

  const initialVariables = useMemo(
    () => ({
      searchControl: inputValues.searchControl,
      filters: inputValues.filters,
      page: currentPage,
      resultsPerPage: 10,
      subGeoFilters: subGeoFilterValues.length
        ? subGeoFilterValues
        : initialSubGeoFilters || [],
      productSorting: sortingValue,
    }),
    [currentPage, inputValues, initialSubGeoFilters, sortingValue]
  );

  const { data, loading } = useGetSrlSingleQuery({
    ssr: false,
    variables: initialVariables,
    skip,
    onCompleted: function (completedData) {
      onFetchMore?.(completedData?.srl.search.single);
    },
  });

  return useMemo(() => {
    return {
      data: data?.srl.search.single,
      loading,
      onPaginate,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initialVariables, loading]);
};
