import { useTranslation } from 'next-i18next';
import { TravelType } from '@hotelplan/graphql.types';

const useOfferTypeDescription = (
  travelType: TravelType,
  count: number
): string => {
  const [t] = useTranslation(['search']);
  return t(
    travelType === TravelType.Package
      ? 'search:search.status.package.prefix'
      : 'search:search.status.hotel.prefix',
    { count }
  );
};

export default useOfferTypeDescription;
