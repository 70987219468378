import { useSearchState } from '@hotelplan/libs.search-state';
import { ISrlState } from 'components/domain/srl/SRL.types';

export default function useResetRadiusSearch() {
  const { setState } = useSearchState<ISrlState>();
  return function () {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        radius: null,
      },
    }));
  };
}
