import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SrlSingleResultFragmentDoc } from './GetSRLResults.generated';
import { SrlEmptyResultFragmentDoc } from './SrlEmptyResult.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlSingleQueryVariables = Types.Exact<{
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  page: Types.Scalars['Int'];
  productSorting?: Types.InputMaybe<Types.SrlProductSorting>;
  groupSorting?: Types.InputMaybe<Types.SrlGroupSorting>;
  resultsPerPage?: Types.InputMaybe<Types.Scalars['Int']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlSingleQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    search: {
      __typename?: 'SrlResultContext';
      single:
        | {
            __typename?: 'SrlEmptyResult';
            message?: string | null;
            status: Types.Status;
            altTravelType: Types.TravelType;
            alternativeSearchUrl?: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            } | null;
          }
        | {
            __typename?: 'SrlSingleResult';
            status: Types.Status;
            message?: string | null;
            productsTotal: number;
            cheapestProduct: {
              __typename?: 'SrlProductItem';
              offerId: string;
              price: { __typename?: 'Price'; amount: string; currency: string };
            };
            page: {
              __typename?: 'Page';
              pageNumber: number;
              resultsTotal?: number | null;
              resultsPerPage?: number | null;
            };
            items: Array<
              | { __typename?: 'NudgeItem' }
              | {
                  __typename?: 'SrlProductItem';
                  giata: string;
                  adults: number;
                  children: number;
                  hpRating?: number | null;
                  taRating?: number | null;
                  provider?: string | null;
                  productCode?: string | null;
                  type: Types.ProductType;
                  distanceInfo?: string | null;
                  duration: number;
                  mealType: string;
                  name: string;
                  departureDate: string;
                  returnDate: string;
                  productClass: Types.ProductClass;
                  fromTo?: string | null;
                  offerId: string;
                  inWishlist: boolean;
                  transferIncluded?: boolean | null;
                  taReviews?: number | null;
                  travelType?: string | null;
                  image: {
                    __typename?: 'Image';
                    alt: string;
                    resized: Array<{
                      __typename?: 'ResizedImage';
                      url: string;
                      id: string;
                    }>;
                  };
                  geoBreadcrumbs: Array<{
                    __typename?: 'GeoObject';
                    id: string;
                    name: string;
                    type: Types.GeoType;
                  }>;
                  link: {
                    __typename?: 'InternalLink';
                    caption?: string | null;
                    uri: string;
                    targetPageType: Types.PageType;
                  };
                  rooms?: Array<{
                    __typename?: 'Room';
                    boardType?: string | null;
                    bookingCode?: string | null;
                    roomType?: string | null;
                  } | null> | null;
                  featureSummary: Array<{
                    __typename?: 'ProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'ProductFeature';
                      id: string;
                      name: string;
                      enabled?: boolean | null;
                    }>;
                  }>;
                  disrupterObject?: {
                    __typename?: 'DisrupterObject';
                    mainTitle?: string | null;
                    subTitle?: string | null;
                    text: string;
                    fontColor?: string | null;
                    backgroundColor?: string | null;
                    iconType?: string | null;
                  } | null;
                  price: {
                    __typename?: 'Price';
                    amount: string;
                    currency: string;
                  };
                  totalPrice: {
                    __typename?: 'Price';
                    amount: string;
                    currency: string;
                  };
                  transferPrice?: {
                    __typename?: 'Price';
                    amount: string;
                    currency: string;
                  } | null;
                }
            >;
          };
    };
  };
};

export const GetSrlSingleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSrlSingle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSorting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlProductSorting' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupSorting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlGroupSorting' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultsPerPage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'search' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'page' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resultsPerPage',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'productSorting' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productSorting' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'groupSorting' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupSorting' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'single' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'srlSingleResult' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'srlEmptyResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlSingleResultFragmentDoc.definitions,
    ...SrlEmptyResultFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlSingleQuery__
 *
 * To run a query within a React component, call `useGetSrlSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlSingleQuery({
 *   variables: {
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      productSorting: // value for 'productSorting'
 *      groupSorting: // value for 'groupSorting'
 *      resultsPerPage: // value for 'resultsPerPage'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlSingleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlSingleQuery,
    GetSrlSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlSingleQuery,
    GetSrlSingleQueryVariables
  >(GetSrlSingleDocument, options);
}
export function useGetSrlSingleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlSingleQuery,
    GetSrlSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlSingleQuery,
    GetSrlSingleQueryVariables
  >(GetSrlSingleDocument, options);
}
export type GetSrlSingleQueryHookResult = ReturnType<
  typeof useGetSrlSingleQuery
>;
export type GetSrlSingleLazyQueryHookResult = ReturnType<
  typeof useGetSrlSingleLazyQuery
>;
export type GetSrlSingleQueryResult = Apollo.QueryResult<
  GetSrlSingleQuery,
  GetSrlSingleQueryVariables
>;
