import { useCallback, useState } from 'react';
import { SrlProductSorting } from '@hotelplan/graphql.types';

export default function useSrlSortingField(defaultSorting: SrlProductSorting) {
  const [sortingState, setSortingState] = useState<{
    productSorting?: SrlProductSorting;
  }>({ productSorting: defaultSorting });

  const onChange = useCallback(
    (sortingType: SrlProductSorting): void => {
      setSortingState(prev => ({
        ...prev,
        productSorting: sortingType,
      }));

      // trackSRLModules({
      //   type: SRLModulesEventType.CHANGE_SORT,
      //   payload: { value: sortingType },
      // });
    },
    [setSortingState]
  );

  return {
    sorting: sortingState.productSorting,
    onChange,
  };
}
