import React from 'react';
import SRLAlternativeSearchModal from './SRLAlternativeSearchModal';
import { EmptyModalWrapper } from './SRLEmptyResults.styled';
import { IAlternativeProps } from './SRLEmptyResults.types';
import SRLNoResults from './SRLNoResults';

function SRLEmptyResults({
  alternativeUrl,
  alternativeTravelType,
}: IAlternativeProps): React.ReactElement {
  return (
    <>
      {!alternativeUrl ? (
        <SRLNoResults />
      ) : (
        <>
          <EmptyModalWrapper>
            <SRLAlternativeSearchModal
              alternativeTravelType={alternativeTravelType}
              alternativeUrl={alternativeUrl}
            />
          </EmptyModalWrapper>
          <SRLNoResults />
        </>
      )}
    </>
  );
}

export default SRLEmptyResults;
