import { useRouter } from 'next/router';
import { useMemo, useRef } from 'react';
import { GeoObject, SrlGeoItem } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { trackSRLResult } from '@hotelplan/libs.tracking';
import { PRODUCT_PARAMS } from 'components/domain/search-query';
import { useGetSrlGroupPreviewQuery } from 'graphql/srl/GetSrlGroupPreview.generated';
import { SrlSingleResultFragment } from 'graphql/srl/GetSRLResults.generated';
import { SrlEmptyResultFragment } from 'graphql/srl/SrlEmptyResult.generated';
import { usePreviewResultsContext } from './preview/PreviewResultsContext';
import { useSortingResultsContext } from './sorting/SortingResultsContext';
import { ISrlState, SrlItem } from './SRL.types';
import { filterSrlItems, sliceSrlItems } from './SRL.utils';
import {
  mapSRLEmptyResultsToTrackableSRLResultsEventMetaData,
  mapSRLSingleResultsToTrackableSRLResultsEventMetaData,
  mapSRLStateToTrackableData,
} from './tracking/SRLTracking.mappers';
import { useSrlCriteriaValues } from './useSrlDataToCriteria';
import { useSrlSingleData } from './useSrlSingle';
import { useSubGeoFiltersContext } from './useSubGeoFiltersContext';

const useSrlProducts = (
  geoObject: GeoObject,
  geoObjects: GeoObject[],
  initialPreviewItems: SrlItem[],
  skip: boolean
) => {
  const { state } = useSearchState<ISrlState>();
  const { sorting } = useSortingResultsContext();
  const { searchControl, filters } = useSrlCriteriaValues();
  const { resultsExpanded, resultsLimit } = usePreviewResultsContext();
  const { subGeoFilterValues } = useSubGeoFiltersContext();

  const { query } = useRouter();

  const now = useRef(Date.now());

  // Product params added to query if user comes from PDP
  // via back to search button
  const defaultProductPage = useMemo(() => {
    const productParams = query[PRODUCT_PARAMS];
    if (productParams) {
      return (
        // we should use page from query only if it's the same geoObject
        (geoObject.id === JSON.parse(productParams as string)?.geoObjectId &&
          Number(JSON.parse(productParams as string)?.page)) ||
        0
      );
    }

    return 0;
  }, [query, geoObject]);

  const searchGeoObjects = useMemo(
    () => (geoObjects && geoObjects.length ? geoObjects : [geoObject]),
    [geoObject, geoObjects]
  );
  const initialSubGeoFilters = searchGeoObjects.map(
    geoSearchObject => geoSearchObject.id
  );

  const {
    data: singleData,
    loading: singleLoading,
    onPaginate,
  } = useSrlSingleData(
    defaultProductPage,
    initialSubGeoFilters,
    res => {
      const then = Date.now();
      const queryTime = then - now.current;

      if (res.__typename === 'SrlSingleResult') {
        trackSRLResult(
          mapSRLSingleResultsToTrackableSRLResultsEventMetaData(
            res,
            {
              ...state,
              productSorting: sorting,
            },
            queryTime
          ),
          mapSRLStateToTrackableData(state)
        );
      } else if (res.__typename === 'SrlEmptyResult') {
        trackSRLResult(
          mapSRLEmptyResultsToTrackableSRLResultsEventMetaData(
            {
              ...state,
              productSorting: sorting,
            },
            queryTime
          ),
          mapSRLStateToTrackableData(state)
        );
      }
    },
    skip
  );

  const { data: groupData, loading: groupLoading } = useGetSrlGroupPreviewQuery(
    {
      ssr: false,
      variables: {
        searchControl,
        filters,
        subGeoFilters: subGeoFilterValues.length
          ? subGeoFilterValues
          : initialSubGeoFilters || [],
      },
      skip: skip || resultsExpanded || initialPreviewItems.length > 0,
    }
  );

  let previewItems = initialPreviewItems;
  const previewGroupResult = groupData?.srl.search.group;
  if (
    previewGroupResult?.__typename === 'SrlGeoGroupResult' &&
    previewGroupResult.groups.length === 1
  ) {
    previewItems = (
      previewGroupResult.groups[0].items.find(
        item =>
          item.__typename === 'SrlGeoItem' && item.geoObject.id === geoObject.id
      ) as SrlGeoItem
    )?.previewItems;
  }

  const page = (singleData as SrlSingleResultFragment)?.page;

  const items = useMemo(
    () =>
      sliceSrlItems(
        filterSrlItems(
          resultsExpanded
            ? (singleData as SrlSingleResultFragment)?.items || []
            : previewItems
        ),
        page,
        resultsLimit
      ),
    [page, previewItems, resultsExpanded, resultsLimit, singleData]
  );

  const expandable =
    !resultsExpanded &&
    filterSrlItems((singleData as SrlSingleResultFragment)?.items || []).filter(
      singleDataItem => !items.find(item => item.giata == singleDataItem.giata)
    ).length > 0;

  return {
    products: { items, page },
    expandable,
    empty: singleData?.__typename === 'SrlEmptyResult' && {
      ...(singleData as SrlEmptyResultFragment),
    },
    loading: singleLoading || groupLoading,
    onPaginate,
  };
};

export default useSrlProducts;
