import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ProductPinContent } from 'components/domain/map/MapProductPin/MapProductPin.styled';

export const GeoPinContent = styled(ProductPinContent)(
  sx2CssThemeFn({
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    backgroundColor: 'secondary',
    border: '2px solid',
    borderColor: 'white',
    borderRadius: '10px 0 10px 0',
    p: 2,
    span: {
      whiteSpace: 'nowrap',
      lineHeight: '1.5',
      fontSize: '12px',
      color: 'white',
    },
    '&:after': {
      borderTop: '10px solid #f60',
    },
  })
);
