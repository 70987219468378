import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  MapRadiusSearchAutocomplete,
  MapRadiusSearchFilter,
  PinOfInterest,
} from '@hotelplan/components.common.map-radius-search';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useRadiusSearchFilter, useResetRadiusSearch } from './hooks';
import {
  RadiusSearchControls,
  RadiusSearchDesktopWrapper,
  RadiusSearchIcon,
  RadiusSearchMobileWrapper,
  ResetButton,
} from './RadiusSearch.styled';

export default function RadiusSearch(): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');
  const [opened, , close, toggle] = useToggleState(false);
  const { radiusFilter, autocompleteValue, onChange, onApply } =
    useRadiusSearchFilter();

  const resetRadiusSearch = useResetRadiusSearch();

  return (
    <>
      {mobile ? (
        <>
          <RadiusSearchIcon onClick={toggle} opened={opened} />
          {opened ? (
            <RadiusSearchMobileWrapper>
              <MapRadiusSearchAutocomplete
                value={autocompleteValue}
                onChange={onChange}
                centerLocation={false}
                showPinOfInterest={false}
              />
              <RadiusSearchControls
                onApply={onApply}
                onReset={() => {
                  resetRadiusSearch();
                  close();
                }}
              />
            </RadiusSearchMobileWrapper>
          ) : null}
          {autocompleteValue?.place && (
            <PinOfInterest
              key={autocompleteValue.place.name}
              coordinates={autocompleteValue.place.coordinates}
              name={autocompleteValue.place.name}
              iconName={'map-radius-search'}
            />
          )}
        </>
      ) : (
        <RadiusSearchDesktopWrapper>
          <MapRadiusSearchFilter
            radiusFilter={radiusFilter}
            pinOfInterestIcon={'map-radius-search'}
            onChange={(place, radius) => {
              onChange({ place, radius });
            }}
          />
          <ResetButton onClick={resetRadiusSearch}>
            {t('cancel.button')}
          </ResetButton>
        </RadiusSearchDesktopWrapper>
      )}
    </>
  );
}
