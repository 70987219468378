import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { SrlGroupSorting, SrlProductSorting } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';
import useSrlSortingField from './useSrlSortingField';

export type ISrlAnySorting = SrlGroupSorting | SrlProductSorting;

export const SRL_SORTING_LABEL_KEYS: Partial<{
  [key in SrlProductSorting]: string;
}> = {
  [SrlProductSorting.ProductBestseller]: 'search:sorting.option.bestseller',
  [SrlProductSorting.ProductPrice]: 'search:sorting.option.price',
  [SrlProductSorting.ProductTaRating]: 'search:sorting.option.taRating',
  [SrlProductSorting.ProductRating]: 'search:sorting.option.hpRating',
  [SrlProductSorting.ProductBd4TravelRecommendation]:
    'search:sorting.option.smartseer',
};

interface ISortingOption {
  value: ISrlAnySorting;
  label: string;
}

interface IAvailableSortingOptions extends ISortingOption {
  availability: {
    mobile: boolean;
    desktop: boolean;
  };
}

const defaultSortingOptions: IAvailableSortingOptions[] = [
  {
    value: SrlProductSorting.ProductBestseller,
    label: SRL_SORTING_LABEL_KEYS.PRODUCT_BESTSELLER,
    availability: {
      desktop: true,
      mobile: true,
    },
  },
  {
    value: SrlProductSorting.ProductPrice,
    label: SRL_SORTING_LABEL_KEYS.PRODUCT_PRICE,
    availability: {
      desktop: true,
      mobile: true,
    },
  },
  {
    value: SrlProductSorting.ProductTaRating,
    label: SRL_SORTING_LABEL_KEYS.PRODUCT_TA_RATING,
    availability: {
      desktop: true,
      mobile: false,
    },
  },
  {
    value: SrlProductSorting.ProductRating,
    label: SRL_SORTING_LABEL_KEYS.PRODUCT_RATING,
    availability: {
      desktop: true,
      mobile: true,
    },
  },
];

const sortingOptionsWithSmartseer: IAvailableSortingOptions[] = [
  {
    value: SrlProductSorting.ProductBd4TravelRecommendation,
    label: SRL_SORTING_LABEL_KEYS.PRODUCT_BD4TRAVEL_RECOMMENDATION,
    availability: {
      desktop: true,
      mobile: true,
    },
  },
  ...defaultSortingOptions,
];

interface ISortingResultsContext {
  options: ISortingOption[];
  sorting: SrlProductSorting;
  setSorting(sorting: ISrlAnySorting | undefined): void;
}

const SortingResultsContext = React.createContext<ISortingResultsContext>({
  options: [],
  sorting: undefined,
  setSorting: noop,
});

export function useSortingResultsContext() {
  return useContext<ISortingResultsContext>(SortingResultsContext);
}

export const SortingResultsProvider: React.FC<PropsWithChildren> = (
  props: PropsWithChildren
) => {
  const { children } = props;
  const { getVariant } = useFeatureToggle();
  const isSmartseerSortingEnabled =
    getVariant(FeatureList.SMARTSEER_SORTING)?.name === 'smartseer-sorting';

  const { sorting, onChange = noop } = useSrlSortingField(
    isSmartseerSortingEnabled
      ? SrlProductSorting.ProductBd4TravelRecommendation
      : SrlProductSorting.ProductBestseller
  );
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();

  const options = useMemo<ISortingOption[]>(() => {
    const availableSortingOptions = isSmartseerSortingEnabled
      ? sortingOptionsWithSmartseer
      : defaultSortingOptions;

    return availableSortingOptions
      .filter(option =>
        mobile ? option.availability.mobile : option.availability.desktop
      )
      .map(option => ({ value: option.value, label: t(option.label) }));
  }, [mobile, t, isSmartseerSortingEnabled]);

  const value = useMemo(() => {
    return {
      options,
      sorting,
      setSorting: onChange,
    };
  }, [options, sorting, onChange]);

  return React.createElement(
    SortingResultsContext.Provider,
    { value },
    children
  );
};
