import { useGoogleMap } from '@react-google-maps/api';
import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Image } from '@hotelplan/components.common.image';
import {
  MapClusterer,
  mapCoordinatesToLatLng,
} from '@hotelplan/components.common.map-pin';
import { GeoObject } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { ProductPin } from 'components/domain/map/Map.types';
import MapClustererRedraw from 'components/domain/map/MapClustererRedraw';
import renderPinContent from 'components/domain/map/MapClusterPin';
import MapProductPin from 'components/domain/map/MapProductPin/MapProductPin';
import useMapFitBounds from 'components/domain/map/useMapFitBounds';
import useMapForm from 'components/domain/map/useMapForm';
import useSelectMapPinHandler from 'components/domain/map/useSelectMapPinHandler';
import PdpMapProductCardsMobile from 'components/domain/pdp/map/PdpMapProductCardsMobile';
import { SRLAlternativeSearchModal } from 'components/domain/srl/SRLEmptyResults';
import RadiusSearch from 'components/domain/srl-map/components/RadiusSearch/RadiusSearch';
import SRLMapGeoPins from 'components/domain/srl-map/components/SRLMapGeoPins/SRLMapGeoPins';
import { FormBackButton } from 'components/domain/srl-map/components/SRLMapSearchForm/SRLMapSearchForm.styled';
import { closeSearchMap } from 'components/domain/srl-map/hooks/useSearchMapEvents';
import useSRLMapPins from './hooks/useSRLMapPins';
import { IMapProductPinsProps, ISearchMapContentProps } from './SRLMap.types';
import {
  DesktopMapProductCard,
  MobileCardContainer,
  PinsLoader,
} from './SRLMapContent.styled';

export default function SearchMapContent({
  activeGeoObject,
  searchedObjects,
  config,
}: ISearchMapContentProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('common');

  const [selectedGeo, setSelectedGeo] = useState<GeoObject | null>(
    activeGeoObject
  );

  const cardContainerRef = useRef<HTMLDivElement>(null);
  const { geoPins, productPins, pinsLoading, noResults } = useSRLMapPins(
    (searchedObjects?.length ? searchedObjects : [selectedGeo]).filter(Boolean)
  );

  if (pinsLoading) {
    return (
      <PinsLoader>
        <Image
          resized={[]}
          src="/images/spinner-button.svg"
          alt="LOADING"
          className="pins-loader"
        />
      </PinsLoader>
    );
  }

  if (noResults) {
    return (
      <SRLAlternativeSearchModal
        resetValues
        searchButtonTranslation={'results:noOffers.searchButton'}
      />
    );
  }

  return (
    <div className={'map-content'}>
      <MapClusterer renderPinContent={renderPinContent} onClusterClick={noop}>
        <MapClustererRedraw>
          {mobile && !config?.hideBackButton ? (
            <FormBackButton onClick={closeSearchMap}>
              {t('common:back.to.results')}
            </FormBackButton>
          ) : null}
          {geoPins?.length ? (
            <SRLMapGeoPins
              geoPins={geoPins}
              cardContainerRef={cardContainerRef}
              setSelectedGeo={setSelectedGeo}
            />
          ) : null}
          {productPins?.length ? (
            <MapProductPins
              productPins={productPins}
              cardContainerRef={cardContainerRef}
            />
          ) : null}
        </MapClustererRedraw>
      </MapClusterer>
      {mobile ? <MobileCardContainer ref={cardContainerRef} /> : null}
      {!config?.hideRadiusSearch ? <RadiusSearch /> : null}
    </div>
  );
}

function MapProductPins({
  productPins,
  cardContainerRef,
}: IMapProductPinsProps): React.ReactElement {
  const map = useGoogleMap();
  const { mobile } = useDeviceType();

  const { selectedObjects, deselect, onSelectObjects } =
    useMapForm<ProductPin>();

  const selectProductHandler = useSelectMapPinHandler<ProductPin>(
    map,
    onSelectObjects
  );

  useMapFitBounds({
    map,
    pins: productPins,
  });

  return (
    <>
      {productPins.map((product, index) => {
        return (
          <MapProductPin
            key={`${index}-${mapCoordinatesToLatLng(
              product.coordinates
            ).toString()}`}
            product={product}
            onClick={selectProductHandler}
            onClickOnSelectedPin={deselect}
            isCurrentProduct={false}
            isSelected={product.id === selectedObjects[0]?.id}
          />
        );
      })}
      {mobile ? (
        ReactDOM.createPortal(
          <PdpMapProductCardsMobile
            selectedProduct={selectedObjects[0]}
            onCloseCard={deselect}
          />,
          cardContainerRef.current
        )
      ) : (
        <DesktopMapProductCard
          selectedProduct={selectedObjects[0]}
          onCloseCard={deselect}
        />
      )}
    </>
  );
}
