import React, { useMemo, useRef } from 'react';
import { ICommonModalProps } from '@hotelplan/components.common.modals';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import SRLMapSearchForm from 'components/domain/srl-map/components/SRLMapSearchForm/SRLMapSearchForm';
import useSearchMapEvents, {
  closeSearchMap,
} from 'components/domain/srl-map/hooks/useSearchMapEvents';
import { scrollToTop } from 'libs';
import SRLMap from './SRLMap';
import {
  BaseSRLMapModal,
  MapContainerWrapper,
  MapFiltersWrapper,
  ModelContentWrapper,
} from './SRLMapContainer.styled';

export default function SRLMapContainer(): React.ReactElement {
  const { mobile } = useDeviceType();
  const [isOpened, open, close] = useToggleState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  function handleOpenMap() {
    open();
    // To show header
    scrollToTop('auto');
  }

  const { activeGeoObject, searchedObjects } =
    useSearchMapEvents({
      openMap: handleOpenMap,
      closeMap: close,
    }) || {};

  const modalProps: ICommonModalProps = useMemo(
    () => ({
      name: 'srl-map-modal',
      label: 'label',
      show: isOpened,
      onClose: closeSearchMap,
    }),
    [isOpened]
  );

  return (
    <BaseSRLMapModal {...modalProps}>
      <ModelContentWrapper>
        {!mobile ? (
          <MapFiltersWrapper>
            <SRLMapSearchForm />
          </MapFiltersWrapper>
        ) : null}
        <MapContainerWrapper ref={mapContainerRef}>
          <SRLMap
            mapContainerRef={mapContainerRef}
            activeGeoObject={activeGeoObject}
            searchedObjects={searchedObjects}
          />
        </MapContainerWrapper>
      </ModelContentWrapper>
    </BaseSRLMapModal>
  );
}
