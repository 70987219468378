import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { SRLMapContainer } from 'components/domain/srl-map';
import { SrlGroupResultFragment } from 'graphql/srl/GetSRLResults.generated';
import SearchStatus from './SearchStatus';
import { FilterButton, SRLResultsWrapper } from './SRL.common';
import SRLEmptyResults from './SRLEmptyResults/SRLEmptyResults';
import SrlGroups from './SrlGroups';
import SRLMobileCurrentSearch from './SRLMobileCurrentSearch';
import SRLResultsSkeleton from './SRLResults.skeleton';
import { useSRLTracking } from './tracking';
import { useSRLResult } from './useSRLResult';

const SrlMobileOverlayHeader = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    pt: 3,
    borderTop: '1px solid',
    borderColor: 'borderGray',
    bg: 'backgroundGray',
    gap: 2,
    mt: [3, 0],
  })
);

interface ISearchResultsProps {
  objectName?: string;
  openMobileOverlay?: () => void;
}

export default function SearchResults(
  props: ISearchResultsProps
): React.ReactElement {
  return (
    <>
      <SRLMapContainer />
      <SRLResultsContent {...props} />
    </>
  );
}

function SRLResultsContent({
  objectName,
  openMobileOverlay,
}: ISearchResultsProps) {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');
  const track = useSRLTracking();

  const { data: srlData, loading, travelType } = useSRLResult(track);

  if (loading) {
    return <SRLResultsSkeleton />;
  }

  switch (srlData?.__typename) {
    case 'SrlEmptyResult': {
      const searchStatusProps = {
        searchAmount: 0,
        travelType,
        objectName,
      };

      return (
        <>
          {mobile ? (
            <>
              <SrlMobileOverlayHeader>
                <div className={'amount-wrapper'}>
                  <SearchStatus {...searchStatusProps} />
                </div>
                <FilterButton onClick={openMobileOverlay}>
                  {t('filter.overlay.title')}
                </FilterButton>
              </SrlMobileOverlayHeader>
              <SRLMobileCurrentSearch />
              <SRLEmptyResults
                alternativeUrl={
                  srlData.alternativeSearchUrl?.uri
                    ? srlData.alternativeSearchUrl
                    : undefined
                }
                alternativeTravelType={srlData.altTravelType}
              />
            </>
          ) : (
            <SRLEmptyResults
              alternativeUrl={
                srlData.alternativeSearchUrl?.uri
                  ? srlData.alternativeSearchUrl
                  : undefined
              }
              alternativeTravelType={srlData.altTravelType}
            />
          )}
        </>
      );
    }
    case 'SrlGeoGroupResult': {
      const { productsTotal, cheapestGroup } = srlData || {};

      const searchStatusProps = {
        searchAmount: productsTotal,
        travelType: travelType,
        cheapestPrice: cheapestGroup?.perPersonPrice.amount,
        objectName,
      };

      return (
        <SRLGroupContainer result={srlData}>
          {mobile ? (
            <>
              <SrlMobileOverlayHeader>
                <div className={'amount-wrapper'}>
                  <SearchStatus {...searchStatusProps} />
                </div>
                <FilterButton onClick={openMobileOverlay}>
                  {t('filter.overlay.title')}
                </FilterButton>
              </SrlMobileOverlayHeader>
              <SRLMobileCurrentSearch />
            </>
          ) : (
            <SearchStatus {...searchStatusProps} />
          )}
        </SRLGroupContainer>
      );
    }
  }
}

interface ISRLGroupContainerProps {
  result: SrlGroupResultFragment;
}

function SRLGroupContainer(
  props: PropsWithChildren<ISRLGroupContainerProps>
): React.ReactElement {
  const { children, result } = props;

  return (
    <SRLResultsWrapper>
      {children}
      <SrlGroups groups={result.groups} />
    </SRLResultsWrapper>
  );
}
