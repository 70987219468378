import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { GeoObject } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { usePreviewResultsContext } from './preview/PreviewResultsContext';
import { SrlItem } from './SRL.types';
import SRLEmptyResults from './SRLEmptyResults/SRLEmptyResults';
import SrlPagination from './SrlPagination';
import SRLProductsSkeleton from './SRLProducts.skeleton';
import SrlProductsList from './SrlProductsList';
import { trackSRLLoadMoreButtonClick } from './tracking';
import useSrlProducts from './useSrlProducts';

const Container = styled.div(
  sx2CssThemeFn({
    pt: 3,
    position: 'relative',
  })
);

const FooterWrapper = styled.div(
  sx2CssThemeFn({
    textAlign: 'right',
    p: 2,
  })
);

const SeeMoreProductsButton = styled(Button).attrs({
  variant: 'btnWithIcon',
  icon: { name: 'chevron-down' },
})(({ theme }) => theme.buttons.blueGradientButton, sx2CssThemeFn({}));

interface ISrlProductsContainerProps {
  geoObject: GeoObject;
  geoSearchObjects: GeoObject[];
  previewItems: SrlItem[];
  active?: boolean;
}

const SrlProductsContainer: React.FC<ISrlProductsContainerProps> = (
  props: ISrlProductsContainerProps
) => {
  const { geoObject, geoSearchObjects, previewItems, active = true } = props;
  const [t, { language }] = useTranslation('search');
  const containerRef = useRef<HTMLDivElement>();
  const { resultsExpanded, onExpandResults, resultsLimit } =
    usePreviewResultsContext();

  const {
    products: { items, page },
    expandable,
    empty,
    loading,
    onPaginate,
  } = useSrlProducts(geoObject, geoSearchObjects, previewItems, !active);

  if (loading) {
    return <SRLProductsSkeleton count={resultsExpanded ? 10 : resultsLimit} />;
  }

  // avoid any rendering if the accordion is closed
  if (!active) return null;

  if (items.length > 0) {
    return (
      <Container ref={containerRef}>
        <SrlProductsList
          items={items}
          page={page.pageNumber}
          geoObjectId={geoObject.id}
        />
        <FooterWrapper>
          {resultsExpanded ? (
            <SrlPagination
              pageData={page}
              onPaginate={onPaginate}
              containerRef={containerRef}
            />
          ) : (
            expandable && (
              <SeeMoreProductsButton
                onClick={() => {
                  onExpandResults();
                  trackSRLLoadMoreButtonClick(language);
                }}
              >
                {t('search:search.seeMoreProducts')}
              </SeeMoreProductsButton>
            )
          )}
        </FooterWrapper>
      </Container>
    );
  } else {
    return (
      <SRLEmptyResults
        alternativeUrl={
          empty.alternativeSearchUrl?.uri
            ? empty.alternativeSearchUrl
            : undefined
        }
        alternativeTravelType={empty.altTravelType}
      />
    );
  }
};

export default SrlProductsContainer;
