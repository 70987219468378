export enum GeoPrefix {
  Continent = 'k',
  CountryGroup = 'g',
  Country = 'c',
  Region = 'r',
  Destination = 'd',
}

export enum GeoTabsKey {
  Srl = 'geo-srl',
  Children = 'geo-children',
  Facts = 'geo-facts',
  Climate = 'geo-climate',
  Map = 'geo-map',
}
