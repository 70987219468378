import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useSearchState } from '@hotelplan/libs.search-state';
import useGeoSearchCriteria from 'components/domain/geo/hooks/useGeoSearchCriteria';
import { ISrlState } from 'components/domain/srl/SRL.types';

// As we don't have searchState on GeoMap, we need to use the searchControlFormState
// and filtersFormState from useGeoSearchCriteria
export default function useSrlMapSearchState() {
  const pageType = usePageType<PageType>();

  const { state, loading } = useSearchState<ISrlState>();
  const { searchControlFormState, filtersFormState } = useGeoSearchCriteria(
    pageType !== PageType.GeoMap || !!state
  );

  return {
    searchControl: state?.searchControl || searchControlFormState,
    filters: state?.filters || filtersFormState,
    loading,
  };
}
