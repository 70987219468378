import { useSearchState } from '@hotelplan/libs.search-state';
import { trackSRLResult } from '@hotelplan/libs.tracking';
import { ISrlState } from 'components/domain/srl/SRL.types';
import { GetSrlResultsQuery } from 'graphql/srl/GetSRLResults.generated';
import {
  mapSRLResultsToTrackableData,
  mapSRLStateToTrackableData,
} from './SRLTracking.mappers';

type TSRLTrackFn = (res: GetSrlResultsQuery, queryTime?: number) => void;

export default function useSRLTracking(): TSRLTrackFn {
  const { state } = useSearchState<ISrlState>();

  return function (res: GetSrlResultsQuery, queryTime?: number) {
    if (res.srl.search.group.__typename === 'SrlEmptyResult') {
      trackSRLResult(
        mapSRLResultsToTrackableData(res, state, queryTime),
        mapSRLStateToTrackableData(state)
      );
    }
  };
}
