import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const SRLResultSkeletonItem = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 412, h: 60 }
    : { w: 947, h: 56 };

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: height,
      }}
    >
      <PlaceholderLoader
        width={width}
        height={height}
        uid="cardSkeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        {mobile ? (
          <>
            <rect x="15" y="19" width="148" height="20" rx="6" />
            <rect x="171" y="19" width="28" height="20" rx="6" />
            <rect x="665" y="15" width="67" height="26" rx="6" />
            <rect x="619" y="15" width="40" height="26" rx="6" />
            <rect x="411" y="15" width="50" height="24" rx="6" />
            <rect x="354" y="15" width="50" height="24" rx="6" />
            <rect x="515" y="24" width="32" height="15" rx="6" />
            <rect x="554" y="24" width="55" height="15" rx="6" />
            <rect x="741" width="1" height="56" />
            <rect
              x="751.609"
              y="22.4648"
              width="1"
              height="8.40628"
              transform="rotate(-46.7222 751.609 22.4648)"
            />
            <rect
              x="752.751"
              y="33.9727"
              width="1"
              height="8.40628"
              transform="rotate(-138.669 752.751 33.9727)"
            />
            <rect width="770" height="1" />
            <rect y="55" width="770" height="1" />
            <rect width="1" height="56" />
            <rect x="769" width="1" height="56" />
          </>
        ) : (
          <>
            <rect width="1" height="55" />
            <rect x="946" width="1" height="55" />
            <rect width="946" height="1" />
            <rect y="55" width="946" height="1" />
            <rect x="13" y="13" width="233" height="31" rx="8" />
            <rect x="517" y="12" width="233" height="31" rx="8" />
            <rect x="783" y="13" width="150" height="31" rx="8" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

const SRLStatusSkeleton: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { mobile } = useDeviceType();

  return (
    <div data-id="srl-status-skeleton" className={className}>
      <PlaceholderLoader
        uid={'SRLStatusSkeleton'}
        width={mobile ? 327 : 770}
        height={mobile ? 21 : 50}
        style={{
          width: '100%',
          height: mobile ? '21px' : '50px',
        }}
      >
        {!mobile && (
          <>
            <rect x="61" y="13" width="378" height="27" rx="10" />
            <rect y="13" width="51" height="27" rx="10" />
          </>
        )}

        {mobile && (
          <>
            <rect x="45" width="282" height="21" rx="10" />
            <rect width="36" height="21" rx="10" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

const SrlWrapper = styled.div(
  sx2CssThemeFn({
    mt: [4, 0],
    pl: [0, 3],
    width: '100%',
  })
);

const SRLResultsSkeleton: React.FC = () => {
  const counts = 10;
  return (
    <SrlWrapper>
      <SRLStatusSkeleton />
      {Array.from({ length: counts }, (_, i) => (
        <div
          key={i}
          style={{
            width: '100%',
          }}
        >
          <SRLResultSkeletonItem />
        </div>
      ))}
    </SrlWrapper>
  );
};

export default SRLResultsSkeleton;
