import { useGoogleMap } from '@react-google-maps/api';
import React from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import MapGeoPin from 'components/domain/map/MapGeoPin/MapGeoPin';
import useMapFitBounds from 'components/domain/map/useMapFitBounds';
import GeoPinCard from './GeoPinCard';
import useSrlMapGeoPinsHandlers from './hooks/useSrlMapGeoPinHandlers';
import { IMapGeoPinsProps } from './SRLMapGeoPins.types';

export default function SRLMapGeoPins({
  geoPins,
  cardContainerRef,
  showCard,
  setSelectedGeo,
}: IMapGeoPinsProps): React.ReactElement {
  const map = useGoogleMap();

  const { selectedGeoObject, deselect, handleGeoPinClick } =
    useSrlMapGeoPinsHandlers({
      map,
      showCard,
      setSelectedGeo,
    });

  useMapFitBounds({
    map,
    pins: geoPins,
  });

  return (
    <>
      {geoPins.map((geoPin, index) => {
        return (
          <MapGeoPin
            key={`${index}-${mapCoordinatesToLatLng(
              geoPin.coordinates
            ).toString()}`}
            geoPin={geoPin}
            onClick={handleGeoPinClick}
            onClickOnSelectedPin={deselect}
            isSelected={geoPin.geo.id === selectedGeoObject?.geo.id}
          />
        );
      })}
      {showCard && selectedGeoObject ? (
        <GeoPinCard
          cardContainerRef={cardContainerRef}
          selectedGeoObject={selectedGeoObject}
          onClick={() => setSelectedGeo(selectedGeoObject?.geo)}
          onClose={deselect}
        />
      ) : null}
    </>
  );
}
