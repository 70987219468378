import { useCallback, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const COLLAPSED_PRODUCTS_COUNT_DESKTOP = 2;
const COLLAPSED_PRODUCTS_COUNT_MOBILE = 5;

export const usePreviewResults = (initialExpandedState = false) => {
  const { mobile } = useDeviceType();

  const [resultsExpanded, setResultsExpanded] =
    useState<boolean>(initialExpandedState);

  const onExpandResults = useCallback(() => {
    setResultsExpanded(true);
  }, [setResultsExpanded]);

  const resultsLimit = resultsExpanded
    ? Infinity
    : mobile
    ? COLLAPSED_PRODUCTS_COUNT_MOBILE
    : COLLAPSED_PRODUCTS_COUNT_DESKTOP;

  return {
    resultsExpanded,
    onExpandResults,
    resultsLimit,
  };
};
