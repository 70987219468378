const SRL_MAP_ZOOM = 14;
const SRL_MAP_TYPE_CONTROL_POSITION = 11;
const SRL_MAP_STREET_VIEW_CONTROL_POSITION = 9;

interface ISRLMapConfig {
  zoom: number;
  options: google.maps.MapOptions;
}

function useSRLMapConfig(): ISRLMapConfig {
  return {
    zoom: SRL_MAP_ZOOM,
    options: {
      mapTypeControlOptions: {
        position: SRL_MAP_TYPE_CONTROL_POSITION,
      },
      fullscreenControl: false,
      streetViewControl: true,
      streetViewControlOptions: {
        position: SRL_MAP_STREET_VIEW_CONTROL_POSITION,
      },
    },
  };
}

export default useSRLMapConfig;
