import noop from 'lodash/noop';
import omit from 'lodash/omit';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  GeoObject,
  GeoType,
  TravelDestinationInput,
} from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { filterTravelDestinationByThemeObjects } from 'components/domain/search-control/utils/SearchControl.utils';
import {
  SubGeoFilterItemFragment,
  useGetSubGeoFilersContentQuery,
} from 'graphql/srl/GetSubGeoFiltersContent.generated';
import { ISrlState } from './SRL.types';

const excludedGeoTypes = [GeoType.Resort, GeoType.Destination];

type TSubGeoFiltersContext = {
  subGeoFilterItems: SubGeoFilterItemFragment[];
  subGeoFilterValues: string[];
  onChange: (nextValue: string) => void;
  loading?: boolean;
};

const SubGeoFiltersContextDefaultValues: TSubGeoFiltersContext = {
  subGeoFilterItems: [],
  subGeoFilterValues: [],
  loading: false,
  onChange: noop,
};

const SubGeoFiltersContext = React.createContext<TSubGeoFiltersContext>(
  SubGeoFiltersContextDefaultValues
);

interface ISubGeoFiltersContextProviderProps {
  defaultItems: GeoObject[];
  activeGeoObject: GeoObject;
  isActive?: boolean;
}

export default function SubGeoFiltersContextProvider({
  children,
  defaultItems,
  activeGeoObject,
  isActive,
}: PropsWithChildren<ISubGeoFiltersContextProviderProps>) {
  const {
    state: { searchControl, filters },
  } = useSearchState<ISrlState>();

  const [subGeoFilterItems, setSubGeoFilterItems] = useState<
    SubGeoFilterItemFragment[]
  >([]);
  const [subGeoFilterValues, setSubGeoFilterValues] = useState<string[]>([]);

  const subDestinations = (
    defaultItems.length
      ? defaultItems.filter(item => !excludedGeoTypes.includes(item.type))
      : [activeGeoObject]
  ).map(item => omit(item, '__typename'));

  const { loading } = useGetSubGeoFilersContentQuery({
    variables: {
      searchControl: {
        ...mapSearchControlToCriteria(searchControl),
        travelDestinations: [
          ...subDestinations,
          ...filterTravelDestinationByThemeObjects(
            searchControl.travelDestination
          ).destinations.map(destination => ({
            id: destination.id,
            name: destination.name,
            type: destination.type,
          })),
        ] as TravelDestinationInput[],
      },
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
    },
    skip: !subDestinations.length || !isActive,
    // TODO: get rid of network-only
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: completedData => {
      const items = completedData?.srl.searchControls.subGeoFilter?.items;
      const values =
        items?.filter(item => item.selected).map(item => item.id) || [];

      setSubGeoFilterItems(items);
      setSubGeoFilterValues(prev => [...prev, ...values]);
    },
  });

  const handleChangeSubGeoFilters = useCallback(
    function (nextValue: string) {
      const newSubGeoFilters = subGeoFilterValues?.includes(nextValue)
        ? [...subGeoFilterValues].filter(v => v !== nextValue)
        : [...subGeoFilterValues, nextValue];

      setSubGeoFilterValues(newSubGeoFilters);
    },
    [subGeoFilterValues]
  );

  const ctx: TSubGeoFiltersContext = useMemo(
    () => ({
      subGeoFilterItems,
      subGeoFilterValues,
      onChange: handleChangeSubGeoFilters,
      loading,
    }),
    [loading, subGeoFilterItems, subGeoFilterValues]
  );

  return (
    <SubGeoFiltersContext.Provider value={ctx}>
      {children}
    </SubGeoFiltersContext.Provider>
  );
}

export function useSubGeoFiltersContext() {
  return useContext(SubGeoFiltersContext);
}
