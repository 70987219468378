import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { SrlEmptyResultFragmentDoc } from './SrlEmptyResult.generated';
import { GeoFragmentDoc } from 'graphql/geo/Geo.generated';
import { ProductItemFragmentDoc } from './SRLProductItem.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlGroupPreviewQueryVariables = Types.Exact<{
  searchControl: Types.SrlSearchControlCriteriaInput;
  subGeoFilters?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  filters?: Types.InputMaybe<Types.SrlFilterCriteriaInput>;
  productSorting?: Types.InputMaybe<Types.SrlProductSorting>;
  groupSorting?: Types.InputMaybe<Types.SrlGroupSorting>;
  forceSingleView?: Types.InputMaybe<Types.Scalars['Boolean']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetSrlGroupPreviewQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    search: {
      __typename?: 'SrlResultContext';
      group:
        | {
            __typename?: 'SrlEmptyResult';
            message?: string | null;
            status: Types.Status;
            altTravelType: Types.TravelType;
            alternativeSearchUrl?: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            } | null;
          }
        | {
            __typename?: 'SrlGeoGroupResult';
            status: Types.Status;
            message?: string | null;
            groups: Array<{
              __typename?: 'SrlGeoGroupItem';
              items: Array<
                | { __typename?: 'NudgeItem' }
                | {
                    __typename?: 'SrlGeoItem';
                    geoObject: {
                      __typename?: 'GeoObject';
                      id: string;
                      name: string;
                      type: Types.GeoType;
                    };
                    previewItems?: Array<
                      | { __typename?: 'NudgeItem' }
                      | {
                          __typename?: 'SrlProductItem';
                          giata: string;
                          adults: number;
                          children: number;
                          hpRating?: number | null;
                          taRating?: number | null;
                          provider?: string | null;
                          productCode?: string | null;
                          type: Types.ProductType;
                          distanceInfo?: string | null;
                          duration: number;
                          mealType: string;
                          name: string;
                          departureDate: string;
                          returnDate: string;
                          productClass: Types.ProductClass;
                          fromTo?: string | null;
                          offerId: string;
                          inWishlist: boolean;
                          transferIncluded?: boolean | null;
                          taReviews?: number | null;
                          travelType?: string | null;
                          image: {
                            __typename?: 'Image';
                            alt: string;
                            resized: Array<{
                              __typename?: 'ResizedImage';
                              url: string;
                              id: string;
                            }>;
                          };
                          geoBreadcrumbs: Array<{
                            __typename?: 'GeoObject';
                            id: string;
                            name: string;
                            type: Types.GeoType;
                          }>;
                          link: {
                            __typename?: 'InternalLink';
                            caption?: string | null;
                            uri: string;
                            targetPageType: Types.PageType;
                          };
                          rooms?: Array<{
                            __typename?: 'Room';
                            boardType?: string | null;
                            bookingCode?: string | null;
                            roomType?: string | null;
                          } | null> | null;
                          featureSummary: Array<{
                            __typename?: 'ProductFeatureGroup';
                            id: string;
                            name: string;
                            features: Array<{
                              __typename?: 'ProductFeature';
                              id: string;
                              name: string;
                              enabled?: boolean | null;
                            }>;
                          }>;
                          disrupterObject?: {
                            __typename?: 'DisrupterObject';
                            mainTitle?: string | null;
                            subTitle?: string | null;
                            text: string;
                            fontColor?: string | null;
                            backgroundColor?: string | null;
                            iconType?: string | null;
                          } | null;
                          price: {
                            __typename?: 'Price';
                            amount: string;
                            currency: string;
                          };
                          totalPrice: {
                            __typename?: 'Price';
                            amount: string;
                            currency: string;
                          };
                          transferPrice?: {
                            __typename?: 'Price';
                            amount: string;
                            currency: string;
                          } | null;
                        }
                    > | null;
                  }
              >;
            }>;
          };
    };
  };
};

export type SrlGroupPreviewResultFragment = {
  __typename?: 'SrlGeoGroupResult';
  status: Types.Status;
  message?: string | null;
  groups: Array<{
    __typename?: 'SrlGeoGroupItem';
    items: Array<
      | { __typename?: 'NudgeItem' }
      | {
          __typename?: 'SrlGeoItem';
          geoObject: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          };
          previewItems?: Array<
            | { __typename?: 'NudgeItem' }
            | {
                __typename?: 'SrlProductItem';
                giata: string;
                adults: number;
                children: number;
                hpRating?: number | null;
                taRating?: number | null;
                provider?: string | null;
                productCode?: string | null;
                type: Types.ProductType;
                distanceInfo?: string | null;
                duration: number;
                mealType: string;
                name: string;
                departureDate: string;
                returnDate: string;
                productClass: Types.ProductClass;
                fromTo?: string | null;
                offerId: string;
                inWishlist: boolean;
                transferIncluded?: boolean | null;
                taReviews?: number | null;
                travelType?: string | null;
                image: {
                  __typename?: 'Image';
                  alt: string;
                  resized: Array<{
                    __typename?: 'ResizedImage';
                    url: string;
                    id: string;
                  }>;
                };
                geoBreadcrumbs: Array<{
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                }>;
                link: {
                  __typename?: 'InternalLink';
                  caption?: string | null;
                  uri: string;
                  targetPageType: Types.PageType;
                };
                rooms?: Array<{
                  __typename?: 'Room';
                  boardType?: string | null;
                  bookingCode?: string | null;
                  roomType?: string | null;
                } | null> | null;
                featureSummary: Array<{
                  __typename?: 'ProductFeatureGroup';
                  id: string;
                  name: string;
                  features: Array<{
                    __typename?: 'ProductFeature';
                    id: string;
                    name: string;
                    enabled?: boolean | null;
                  }>;
                }>;
                disrupterObject?: {
                  __typename?: 'DisrupterObject';
                  mainTitle?: string | null;
                  subTitle?: string | null;
                  text: string;
                  fontColor?: string | null;
                  backgroundColor?: string | null;
                  iconType?: string | null;
                } | null;
                price: {
                  __typename?: 'Price';
                  amount: string;
                  currency: string;
                };
                totalPrice: {
                  __typename?: 'Price';
                  amount: string;
                  currency: string;
                };
                transferPrice?: {
                  __typename?: 'Price';
                  amount: string;
                  currency: string;
                } | null;
              }
          > | null;
        }
    >;
  }>;
};

export type SrlGroupPreviewGeoItemFragment = {
  __typename?: 'SrlGeoItem';
  geoObject: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  };
  previewItems?: Array<
    | { __typename?: 'NudgeItem' }
    | {
        __typename?: 'SrlProductItem';
        giata: string;
        adults: number;
        children: number;
        hpRating?: number | null;
        taRating?: number | null;
        provider?: string | null;
        productCode?: string | null;
        type: Types.ProductType;
        distanceInfo?: string | null;
        duration: number;
        mealType: string;
        name: string;
        departureDate: string;
        returnDate: string;
        productClass: Types.ProductClass;
        fromTo?: string | null;
        offerId: string;
        inWishlist: boolean;
        transferIncluded?: boolean | null;
        taReviews?: number | null;
        travelType?: string | null;
        image: {
          __typename?: 'Image';
          alt: string;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        };
        geoBreadcrumbs: Array<{
          __typename?: 'GeoObject';
          id: string;
          name: string;
          type: Types.GeoType;
        }>;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        rooms?: Array<{
          __typename?: 'Room';
          boardType?: string | null;
          bookingCode?: string | null;
          roomType?: string | null;
        } | null> | null;
        featureSummary: Array<{
          __typename?: 'ProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'ProductFeature';
            id: string;
            name: string;
            enabled?: boolean | null;
          }>;
        }>;
        disrupterObject?: {
          __typename?: 'DisrupterObject';
          mainTitle?: string | null;
          subTitle?: string | null;
          text: string;
          fontColor?: string | null;
          backgroundColor?: string | null;
          iconType?: string | null;
        } | null;
        price: { __typename?: 'Price'; amount: string; currency: string };
        totalPrice: { __typename?: 'Price'; amount: string; currency: string };
        transferPrice?: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        } | null;
      }
  > | null;
};

export const SrlGroupPreviewGeoItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlGroupPreviewGeoItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlGeoItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...GeoFragmentDoc.definitions,
    ...ProductItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const SrlGroupPreviewResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlGroupPreviewResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlGeoGroupResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'srlGroupPreviewGeoItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlGroupPreviewGeoItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetSrlGroupPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSrlGroupPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SrlSearchControlCriteriaInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subGeoFilters' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSorting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlProductSorting' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupSorting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SrlGroupSorting' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceSingleView' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'search' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '10' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subGeoFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'subGeoFilters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'forceSingleView' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'forceSingleView' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'productSorting' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productSorting' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'groupSorting' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupSorting' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'srlGroupPreviewResult',
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'srlEmptyResult' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SrlGroupPreviewResultFragmentDoc.definitions,
    ...SrlEmptyResultFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlGroupPreviewQuery__
 *
 * To run a query within a React component, call `useGetSrlGroupPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlGroupPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlGroupPreviewQuery({
 *   variables: {
 *      searchControl: // value for 'searchControl'
 *      subGeoFilters: // value for 'subGeoFilters'
 *      filters: // value for 'filters'
 *      productSorting: // value for 'productSorting'
 *      groupSorting: // value for 'groupSorting'
 *      forceSingleView: // value for 'forceSingleView'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetSrlGroupPreviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlGroupPreviewQuery,
    GetSrlGroupPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlGroupPreviewQuery,
    GetSrlGroupPreviewQueryVariables
  >(GetSrlGroupPreviewDocument, options);
}
export function useGetSrlGroupPreviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlGroupPreviewQuery,
    GetSrlGroupPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlGroupPreviewQuery,
    GetSrlGroupPreviewQueryVariables
  >(GetSrlGroupPreviewDocument, options);
}
export type GetSrlGroupPreviewQueryHookResult = ReturnType<
  typeof useGetSrlGroupPreviewQuery
>;
export type GetSrlGroupPreviewLazyQueryHookResult = ReturnType<
  typeof useGetSrlGroupPreviewLazyQuery
>;
export type GetSrlGroupPreviewQueryResult = Apollo.QueryResult<
  GetSrlGroupPreviewQuery,
  GetSrlGroupPreviewQueryVariables
>;
