import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { GeoFragmentDoc } from 'graphql/geo/Geo.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { PageFragmentDoc } from 'graphql/fragments/Page.generated';
import { SrlGeoItemFragmentDoc } from './SrlGeoItem.generated';
export type SrlGeoGroupItemFragment = {
  __typename?: 'SrlGeoGroupItem';
  geoObject: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  };
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsTotal?: number | null;
    resultsPerPage?: number | null;
  };
  items: Array<
    | { __typename?: 'NudgeItem' }
    | {
        __typename?: 'SrlGeoItem';
        count: number;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        geoObject: {
          __typename?: 'GeoObject';
          id: string;
          name: string;
          type: Types.GeoType;
        };
        geoSearchObjects?: Array<{
          __typename?: 'GeoObject';
          id: string;
          name: string;
          type: Types.GeoType;
        } | null> | null;
        perPersonPrice: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        };
        previewItems?: Array<
          | { __typename?: 'NudgeItem' }
          | {
              __typename?: 'SrlProductItem';
              giata: string;
              adults: number;
              children: number;
              hpRating?: number | null;
              taRating?: number | null;
              provider?: string | null;
              productCode?: string | null;
              type: Types.ProductType;
              distanceInfo?: string | null;
              duration: number;
              mealType: string;
              name: string;
              departureDate: string;
              returnDate: string;
              productClass: Types.ProductClass;
              fromTo?: string | null;
              offerId: string;
              inWishlist: boolean;
              transferIncluded?: boolean | null;
              taReviews?: number | null;
              travelType?: string | null;
              image: {
                __typename?: 'Image';
                alt: string;
                resized: Array<{
                  __typename?: 'ResizedImage';
                  url: string;
                  id: string;
                }>;
              };
              geoBreadcrumbs: Array<{
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              }>;
              link: {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              };
              rooms?: Array<{
                __typename?: 'Room';
                boardType?: string | null;
                bookingCode?: string | null;
                roomType?: string | null;
              } | null> | null;
              featureSummary: Array<{
                __typename?: 'ProductFeatureGroup';
                id: string;
                name: string;
                features: Array<{
                  __typename?: 'ProductFeature';
                  id: string;
                  name: string;
                  enabled?: boolean | null;
                }>;
              }>;
              disrupterObject?: {
                __typename?: 'DisrupterObject';
                mainTitle?: string | null;
                subTitle?: string | null;
                text: string;
                fontColor?: string | null;
                backgroundColor?: string | null;
                iconType?: string | null;
              } | null;
              price: { __typename?: 'Price'; amount: string; currency: string };
              totalPrice: {
                __typename?: 'Price';
                amount: string;
                currency: string;
              };
              transferPrice?: {
                __typename?: 'Price';
                amount: string;
                currency: string;
              } | null;
            }
        > | null;
      }
  >;
};

export const SrlGeoGroupItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlGeoGroupItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlGeoGroupItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'page' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'srlGeoItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...GeoFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...PageFragmentDoc.definitions,
    ...SrlGeoItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
