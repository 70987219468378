import React from 'react';
import MapGeoCard from './MapGeoCard';
import { DesktopGeoMapPin } from './MapGeoCard.styled';
import { IMapGeoCardProps } from './MapGeoCard.types';

export default function DesktopMapGeoCard(
  props: IMapGeoCardProps
): React.ReactElement {
  const { selectedGeoObject } = props;

  return (
    <DesktopGeoMapPin
      coordinates={selectedGeoObject.coordinates}
      object={selectedGeoObject}
    >
      <MapGeoCard {...props} />
    </DesktopGeoMapPin>
  );
}
