import noop from 'lodash/noop';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { usePreviewResults } from './usePreviewResults';

interface IPreviewResultsContext {
  resultsExpanded: boolean;
  onExpandResults: () => void;
  resultsLimit: number;
}

const PreviewResultsContext = React.createContext<IPreviewResultsContext>({
  resultsExpanded: false,
  onExpandResults: noop,
  resultsLimit: undefined,
});

export function usePreviewResultsContext() {
  return useContext<IPreviewResultsContext>(PreviewResultsContext);
}

interface IPreviewResultsProviderProps {
  isExpanded?: boolean;
}

export const PreviewResultsProvider: React.FC<
  PropsWithChildren<IPreviewResultsProviderProps>
> = (props: PropsWithChildren<IPreviewResultsProviderProps>) => {
  const { isExpanded, children } = props;

  const { resultsExpanded, onExpandResults, resultsLimit } =
    usePreviewResults(isExpanded);

  const value = useMemo(() => {
    return {
      resultsExpanded,
      onExpandResults,
      resultsLimit,
    };
  }, [resultsExpanded, onExpandResults, resultsLimit]);

  return React.createElement(
    PreviewResultsContext.Provider,
    { value },
    children
  );
};
