import { useGoogleMap } from '@react-google-maps/api';
import { useMemo } from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import {
  ITravelDestinationState,
  mapTravelDestinationObjectToCanonical,
} from '@hotelplan/components.common.travel-destination';
import {
  GeoObject,
  TravelDestination,
  TravelDestinationType,
} from '@hotelplan/graphql.types';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { ProductPin, TGeoPin } from 'components/domain/map/Map.types';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import {
  filterTravelDestinationByThemeObjects,
  filterTravelDestinationByTravelDestinationType,
} from 'components/domain/search-control/utils/SearchControl.utils';
import { useGetSrlMapPinsQuery } from 'graphql/srl/map/GetSRLMapPins.generated';
import useSrlMapSearchState from './useSrlMapSearchState';

interface IUseSRLMapPins {
  geoPins: Array<TGeoPin> | null;
  productPins: Array<ProductPin> | null;
  pinsLoading?: boolean;
  noResults?: boolean;
}

export default function useSRLMapPins(
  searchedObjects?: Array<GeoObject> | null
): IUseSRLMapPins {
  const map = useGoogleMap();

  const { searchControl, filters, loading } = useSrlMapSearchState();

  const searchedDestinations: ITravelDestinationState = useMemo(() => {
    const currentTravelDestination = searchControl.travelDestination;
    const useSelectedGeo = !filterTravelDestinationByTravelDestinationType(
      currentTravelDestination,
      [
        TravelDestinationType.Region,
        TravelDestinationType.Destination,
        TravelDestinationType.Hotel,
        TravelDestinationType.Resort,
      ]
    ).destinations.length;

    if (useSelectedGeo && searchedObjects?.length) {
      return {
        destinations: [
          ...searchedObjects.map(obj =>
            mapTravelDestinationObjectToCanonical(
              obj as unknown as TravelDestination
            )
          ),
          ...filterTravelDestinationByThemeObjects(currentTravelDestination)
            .destinations,
        ],
      };
    }
    return currentTravelDestination;
  }, [searchControl.travelDestination, searchedObjects]);

  const pinsRequestVariables = useMemo(
    () => ({
      searchControl: mapSearchControlToCriteria({
        ...searchControl,
        travelDestination: searchedDestinations,
      }),
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
    }),
    [filters, searchControl, searchedDestinations]
  );

  const { data: pinsData, loading: pinsLoading } = useGetSrlMapPinsQuery({
    skip: loading,
    variables: pinsRequestVariables,
    onCompleted({ srl }) {
      const pins = srl.search.mapPins;

      const cheapestPin = pins.productPins.length
        ? pins.cheapestProduct
        : pins.cheapestGeo;

      if (
        cheapestPin &&
        cheapestPin.coordinates.latitude + cheapestPin.coordinates.longitude > 0
      ) {
        map?.setCenter(mapCoordinatesToLatLng(cheapestPin.coordinates));
      }
    },
  });

  const geoPins = pinsData?.srl.search.mapPins.geoPins || null;
  const productPins = pinsData?.srl.search.mapPins.productPins || null;

  return useMemo(
    function () {
      return {
        pinsLoading,
        geoPins,
        productPins,
        noResults: !Boolean(geoPins?.length || productPins?.length),
      };
    },
    [geoPins, productPins, pinsLoading]
  );
}
