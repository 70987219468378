import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import PdpMapProductCardsDesktop from 'components/domain/pdp/map/PdpMapProductCardsDesktop';

export const MobileCardContainer = styled.div({
  width: '100%',
  position: 'absolute',
  bottom: 0,
});

export const PinsLoader = styled.div({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '.pins-loader': {
    width: '100px',
    height: '100px',
  },
});

export const DesktopMapProductCard = styled(PdpMapProductCardsDesktop)(
  sx2CssThemeFn({
    '.card-trip-advisor': {
      alignItems: 'flex-start',
    },
  })
);
