import { useTranslation } from 'next-i18next';
import React from 'react';
import { Price } from '@hotelplan/graphql.types';
import { formatPrice } from '@hotelplan/libs.utils';
import { AnimatedWrapper } from 'components/domain/map/MapProductPin/MapProductPin.styled';
import { GeoPinContent } from './MapGeoPinContent.styled';

interface IMapGeoPinContentProps {
  price?: Omit<Price, 'total'>;
  isSelected?: boolean;
  destinationName: string;
}

export default function MapGeoPinContent(props: IMapGeoPinContentProps) {
  const { price, isSelected = false, destinationName } = props;
  const [t] = useTranslation(['results', 'pdp']);

  const content = (
    <GeoPinContent>
      <span>{destinationName}</span>
      <span className={'price'}>
        {t('common:priceFrom')}&nbsp;
        {price ? formatPrice(price) : t('common:defaultCurrency')}
      </span>
    </GeoPinContent>
  );

  if (isSelected) {
    return <AnimatedWrapper>{content}</AnimatedWrapper>;
  }

  return content;
}
