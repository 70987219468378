export const useScroll = () => {
  return (element: HTMLElement) => {
    const offsetTop =
      document.documentElement.scrollTop + element?.getBoundingClientRect().top;
    if (typeof offsetTop === 'number') {
      window.scrollTo({
        top: offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  };
};
