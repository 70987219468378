import minBy from 'lodash/minBy';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { PRODUCT_PARAMS } from 'components/domain/search-query';
import { SrlGeoItemFragment } from 'graphql/srl/SrlGeoItem.generated';

interface IDefaultAccordion {
  defaultItem: SrlGeoItemFragment;
  defaultItemIndex: number;
  checkIsExpanded?(activeItem: SrlGeoItemFragment): boolean;
}

export default function useDefaultAccordionItem(
  items: SrlGeoItemFragment[]
): IDefaultAccordion {
  const { query } = useRouter();

  // Product params added to query if user comes from PDP
  // trough back to search button
  const defaultProductParams = useMemo(() => {
    const productParams = query[PRODUCT_PARAMS];
    if (productParams) {
      return JSON.parse(productParams as string) || {};
    }
  }, [query]);

  const defaultItem: SrlGeoItemFragment | null = useMemo(() => {
    return defaultProductParams
      ? items.find(function (item) {
          return item.geoObject.id === defaultProductParams?.geoObjectId;
        })
      : null;
  }, [defaultProductParams, items]);

  const minPriceItem: SrlGeoItemFragment = useMemo(() => {
    return minBy(items, item => parseInt(item.perPersonPrice.amount, 10));
  }, [items]);

  // If user changed pagination in SRL accordion before coming to PDP
  // after return back to SRL via back to search button
  // we want to show accordion with the item he selected expanded
  function checkIsExpanded(currentItem: SrlGeoItemFragment) {
    const pageFromQuery = defaultProductParams
      ? Number(defaultProductParams.page)
      : undefined;

    return (
      currentItem.geoObject.id === defaultItem?.geoObject.id &&
      pageFromQuery > 0
    );
  }

  return {
    // If user comes from PDP trough back to search button
    // we want to show the country he selected
    // otherwise we show the country with the lowest price
    defaultItem: defaultItem || minPriceItem,
    defaultItemIndex: defaultItem
      ? items.indexOf(defaultItem)
      : items.indexOf(minPriceItem),
    checkIsExpanded,
  };
}
