import { ITravelDestinationState } from '@hotelplan/components.common.travel-destination';
import { TravelDestinationType } from '@hotelplan/graphql.types';

export function filterTravelDestinationByThemeObjects(
  travelDestination: ITravelDestinationState
): ITravelDestinationState {
  return {
    destinations: [
      ...travelDestination.destinations.filter(
        destination => destination.type === TravelDestinationType.Theme
      ),
    ],
  };
}

export function filterTravelDestinationByTravelDestinationType(
  travelDestination: ITravelDestinationState,
  allowedTypes: Array<TravelDestinationType>
): ITravelDestinationState {
  return {
    destinations: [
      ...travelDestination.destinations.filter(destination =>
        allowedTypes.includes(destination.type)
      ),
    ],
  };
}
