import { useTranslation } from 'next-i18next';
import React from 'react';
import SearchFilters from 'components/domain/filters/SearchFilters';
import SearchControlForm from 'components/domain/search-control/components/SearchControlForm/SearchControlForm';
import { closeSearchMap } from 'components/domain/srl-map/hooks/useSearchMapEvents';
import { FormBackButton, FormWrapper } from './SRLMapSearchForm.styled';

export default function SRLMapSearchForm(): React.ReactElement {
  const [t] = useTranslation('common');

  return (
    <FormWrapper>
      <SearchControlForm
        filtersComponent={<SearchFilters allowMapFiltersAccess={false} />}
        headerComponent={
          <FormBackButton onClick={closeSearchMap}>
            {t('common:back.to.results')}
          </FormBackButton>
        }
      />
    </FormWrapper>
  );
}
