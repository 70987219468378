import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { MapPin } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { TGeoPin } from 'components/domain/map/Map.types';

export const DesktopGeoMapPin = styled(MapPin<TGeoPin>)(
  sx2CssThemeFn({
    '.map-pin-content': {
      bottom: 'initial',
      top: '9px',
      cursor: 'auto',
    },

    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '20px 0 0 20px',
      borderColor: 'transparent transparent transparent white',
      bottom: '-16px',
      left: '50%',
      ml: '-20px',
    },
  })
);

export const GeoCardWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'textGray',
    border: '1px solid',
    borderColor: '#ccc',
    p: 1,
    '.card-info': {
      display: 'flex',
      justifyContent: 'space-between',
      my: 3,
    },
  })
);

export const GeoCardImage = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: ['100%', '320px'],
    img: {
      height: '100%',
      objectFit: 'cover',
    },
  })
);

export const GeoCardTitle = styled.h4(
  sx2CssThemeFn({
    color: ['secondary', 'primary'],
    mb: 2,
    fontSize: [2, '18px'],
  })
);

export const CloseCardButton = styled(Button)(({ theme }) =>
  sx2CssThemeFn({
    position: 'absolute',
    top: 0,
    right: 0,
    p: 1,
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'borderGray',
    backgroundColor: 'backgroundGray',
    ...theme.icons.xlg,
    '.icon': {
      color: 'textGray',
      ...theme.icons.md,
    },
  })
);

export const GeoCheapestPrice = styled.div(
  sx2CssThemeFn({
    fontSize: ['10px', 0],
    fontWeight: 'bold',
    alignSelf: 'flex-start',

    '.title': {
      textTransform: 'uppercase',
      fontSize: 0,
    },

    '.currency': {
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'secondary',
      fontSize: [0, 1],
    },

    '.price': {
      fontFamily: '"Helvetica Neue", sans-serif',
      color: 'secondary',
      fontSize: [1, 2],
    },
  })
);

export const MobileMapGeoCardWrapper = styled.div(
  sx2CssThemeFn({
    mt: 2,
    '.primary-button': {
      py: 3,
    },
  })
);
