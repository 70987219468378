import React from 'react';
import ReactDOM from 'react-dom';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import DesktopMapGeoCard from 'components/domain/map/MapGeoCard/DesktopMapGeoCard';
import MapGeoCard from 'components/domain/map/MapGeoCard/MapGeoCard';
import { MobileMapGeoCardWrapper } from 'components/domain/map/MapGeoCard/MapGeoCard.styled';
import { IGeoPinCardProps } from './SRLMapGeoPins.types';

export default function GeoPinCard(
  props: IGeoPinCardProps
): React.ReactElement {
  const { cardContainerRef, selectedGeoObject, onClick, onClose } = props;
  const { mobile } = useDeviceType();

  return (
    <>
      {mobile ? (
        ReactDOM.createPortal(
          <MobileMapGeoCardWrapper>
            <MapGeoCard
              selectedGeoObject={selectedGeoObject}
              onCloseCard={onClose}
            />
          </MobileMapGeoCardWrapper>,
          cardContainerRef.current
        )
      ) : (
        <DesktopMapGeoCard
          selectedGeoObject={selectedGeoObject}
          onCloseCard={onClose}
          onClick={onClick}
        />
      )}
    </>
  );
}
