import { useMemo } from 'react';
import {
  GeoCriteriaInput,
  SearchControlComponent,
} from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { useGSSContext } from '@hotelplan/libs.gss';
import { useSearchState } from '@hotelplan/libs.search-state';
import useSearchParam from 'components/core/search/useSearchParam';
import {
  mapFiltersToFormState,
  mapSrlFilterValuesToSrlFilterCriteriaInput,
} from 'components/domain/filters/Filters.mappers';
import { IFiltersSearchState } from 'components/domain/filters/Filters.types';
import {
  mapSearchControlToCriteria,
  mapSearchControlValuesToFormState,
  mergeSearchControlState,
} from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { ISrlState } from 'components/domain/srl/SRL.types';
import { useGetGeoSearchControlValuesQuery } from 'graphql/geo/GetGeoSearchControlValues.generated';

interface IUseGeoSearchCriteria {
  loading: boolean;
  geoCriteriaInput: GeoCriteriaInput;
  searchControlFormState?: ISearchControlState;
  filtersFormState?: IFiltersSearchState;
}

export default function useGeoSearchCriteria(
  skip?: boolean
): IUseGeoSearchCriteria {
  const { id } = useObjectId();
  const { state } = useSearchState<ISrlState>();
  const { gss } = useGSSContext<ISearchControlState>();
  const searchParam = useSearchParam();

  const { data: geoSearchControlData, loading: geoSearchControlDataLoading } =
    useGetGeoSearchControlValuesQuery({
      skip,
      variables: {
        id,
        urlParameter: searchParam || '',
      },
    });

  const searchControlFormState =
    state?.searchControl ||
    mapSearchControlValuesToFormState(
      geoSearchControlData?.geo.searchControl as SearchControlComponent
    );

  const filtersFormState =
    state?.filters ||
    mapFiltersToFormState(geoSearchControlData?.geo.filters.mainFilter);

  // NOTE: We should skip GSS if a search query param is not empty by the requirement.
  const mergedSearchControlState = searchParam
    ? searchControlFormState
    : mergeSearchControlState(searchControlFormState, gss);

  return useMemo(
    () => ({
      loading: geoSearchControlDataLoading,
      geoCriteriaInput: {
        ...mapSearchControlToCriteria(mergedSearchControlState),
        filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filtersFormState),
      },
      searchControlFormState,
      filtersFormState,
    }),
    [
      geoSearchControlDataLoading,
      searchControlFormState,
      filtersFormState,
      mergedSearchControlState,
    ]
  );
}
