import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { TravelType } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import useOfferTypeDescription from './useOfferTypeDescription';

const SearchStatusWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: 'baseline',
    mb: [null, 3],
    fontFamily: '"Helvetica Neue",sans-serif',
    '.object-name': {
      color: 'textGray',
      fontSize: ['20px', '28px'],
      mr: '5px',
    },
    '.amount-info': {
      color: 'primary',
      fontSize: ['16px', '22px'],
      mr: '5px',
    },
    '.search-amount': {
      fontSize: ['20px', '28px'],
    },
    '.price-info': {
      color: 'secondary',
      fontSize: ['14px', '16px'],
      mr: '5px',
    },
    '.amount-price': {
      fontSize: ['20px', '26px'],
    },
  })
);

interface ISearchStatusProps {
  travelType: TravelType;
  cheapestPrice?: string;
  searchAmount: number;
  objectName?: string;
  loading?: boolean;
}

export default function SearchStatus({
  travelType,
  searchAmount,
  objectName,
  cheapestPrice,
}: ISearchStatusProps): React.ReactElement {
  const [t] = useTranslation(['common', 'search']);
  const offerTypeDescription = useOfferTypeDescription(
    travelType,
    searchAmount
  );

  return (
    <SearchStatusWrapper>
      <div>
        {objectName && (
          <span className={'object-name'}>{`${objectName}:`}</span>
        )}
        <span className={'search-amount amount-info'}>{searchAmount}</span>
        <span className={'amount-info'}>{offerTypeDescription}</span>
      </div>
      {cheapestPrice ? (
        <div>
          <span className={'price-info'}>{t('common:priceFrom')} CHF</span>
          <span className={'price-info amount-price'}>{cheapestPrice}.-</span>
        </div>
      ) : null}
    </SearchStatusWrapper>
  );
}
