import styled from 'styled-components';
import { ShadowOnlyModal } from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const BaseSRLMapModal = styled(ShadowOnlyModal)(({ theme: { media } }) =>
  sx2CssThemeFn({
    [media.mobile]: {
      maxHeight: 'calc(100% - 85px) !important',
      top: 'auto !important',
      bottom: 0,
      zIndex: 2,
    },
    '.modal-wrapper': {
      width: ['100%', 'calc(100% - 60px)'],
      height: '100%',
      borderRadius: ['roundedTop', '0px'],
      position: 'relative',
      '.modal-close': {
        display: ['none', 'block'],
        right: [0, '40px'],
        top: [0, '24px'],
        zIndex: 1,
        backgroundColor: 'backgroundGray',
        borderLeft: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'borderGray',
        color: 'textGray',
        '.icon': {
          variant: 'icons.md',
        },
      },
    },
    '.modal-body': {
      padding: [0, '24px 40px'],
    },
    '.shadow-overlay': {
      bg: 'rgba(0,0,0,0.5)',
      [media.mobile]: {
        maxHeight: 'calc(100% - 85px) !important',
        top: 'auto !important',
        bottom: 0,
      },
    },
  })
);

export const ModelContentWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  })
);

export const MapFiltersWrapper = styled.div.attrs({
  'data-id': `map-filters-wrapper`,
})(
  sx2CssThemeFn({
    maxHeight: '100%',
    p: '10px',
    bg: 'backgroundGray',
    flexShrink: 0,
    '.search-control-form-wrapper': {
      maxWidth: '250px',
    },
  })
);

export const MapContainerWrapper = styled.div.attrs({
  'data-id': `mapContainerWrapper`,
})(
  sx2CssThemeFn({
    position: 'relative',
    flex: 1,
    '.search-button': {
      bg: 'primary',
    },
  })
);

export const MapProviderWrapper = styled.div(
  sx2CssThemeFn({
    '.gm-svpc': {
      img: {
        minWidth: '30px !important',
        minHeight: '30px !important',
      },
    },
  })
);
