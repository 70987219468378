import { useMemo } from 'react';
import { MapPinView } from '@hotelplan/components.common.map-pin';
import { TGeoPin } from 'components/domain/map/Map.types';
import useMapForm from 'components/domain/map/useMapForm';
import useSelectMapPinHandler from 'components/domain/map/useSelectMapPinHandler';

export default function useSrlMapGeoPinsHandlers({
  map,
  showCard,
  setSelectedGeo,
}) {
  const { selectedObjects, deselect, onSelectObjects } = useMapForm<TGeoPin>();

  const selectProductHandler = useSelectMapPinHandler<TGeoPin>(
    map,
    onSelectObjects
  );

  function handleGeoPinClick(pin: TGeoPin, view: MapPinView<TGeoPin>) {
    if (showCard) selectProductHandler(pin, view);
    else {
      if (pin) {
        setSelectedGeo(pin.geo);
      }
    }
  }

  const selectedGeoObject = useMemo(
    () => selectedObjects[0],
    [selectedObjects]
  );

  return useMemo(
    () => ({
      selectedGeoObject,
      handleGeoPinClick,
      deselect,
    }),
    [selectedGeoObject]
  );
}
