import { useGoogleMap } from '@react-google-maps/api';
import { useEffect, useMemo, useState } from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import {
  TMapFindPlaceValue,
  TMapPlace,
} from '@hotelplan/components.common.map-radius-search';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useSearchState } from '@hotelplan/libs.search-state';
import { ISrlState } from 'components/domain/srl/SRL.types';

export default function useRadiusSearchFilter() {
  const map = useGoogleMap();
  const { mobile } = useDeviceType();

  const {
    state: { filters },
    setState,
  } = useSearchState<ISrlState>();
  const radiusFilter = filters?.radius;

  const [hasApplied, setHasApplied] = useState<boolean>(false);

  const [place, setPlace] = useState<TMapPlace | undefined>(() => {
    if (!radiusFilter) return undefined;
    return {
      coordinates: radiusFilter.center,
      name: radiusFilter.label,
    };
  });

  const [radius, setRadius] = useState<number | undefined>(
    radiusFilter?.radius
  );

  function setRadiusFilterToState() {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        radius: place
          ? {
              radius: radius,
              label: place.name,
              center: place.coordinates,
            }
          : null,
      },
    }));
  }

  useEffect(
    function centerPlaceOnInit() {
      if (radiusFilter) {
        map.setCenter(mapCoordinatesToLatLng(radiusFilter.center));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [radiusFilter]
  );

  useEffect(() => {
    if (!mobile) {
      setRadiusFilterToState();
    }
  }, [mobile, place, radius]);

  // updates the search state whenever the place state changes, but only if the apply button has been clicked.
  // this ensures that the latest place value is used when updating the search state.
  useEffect(() => {
    if (hasApplied && place) {
      setRadiusFilterToState();
    }
  }, [hasApplied, place, radius, setState]);

  useEffect(
    function clearPlaceWhenFilterIsRemoved() {
      if (!radiusFilter && place) {
        setPlace(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [radiusFilter]
  );

  function handleOnChange(nextValue: TMapFindPlaceValue): void {
    const { place: nextPlace, radius: nextRadius } = nextValue;
    setRadius(nextRadius);
    setPlace(nextPlace);
  }

  function handleOnApply() {
    setHasApplied(true);
  }

  return useMemo(
    () => ({
      radiusFilter,
      autocompleteValue: {
        radius,
        place: radiusFilter
          ? {
              name: radiusFilter.label,
              coordinates: radiusFilter.center,
            }
          : undefined,
      },
      onChange: handleOnChange,
      onApply: handleOnApply,
    }),
    [radius, radiusFilter]
  );
}
