import React from 'react';
import { IMapPinProps, MapPin } from '@hotelplan/components.common.map-pin';
import { TGeoPin } from 'components/domain/map/Map.types';
import MapGeoPinContent from './MapGeoPinContent';

interface IMapGeoPinProps
  extends Omit<IMapPinProps<TGeoPin>, 'coordinates' | 'object'> {
  geoPin: TGeoPin;
  isSelected?: boolean;
}

function MapGeoPin(props: IMapGeoPinProps) {
  const { geoPin, isSelected, ...mapPinProps } = props;

  return (
    <MapPin<TGeoPin>
      coordinates={geoPin.coordinates}
      object={geoPin}
      {...mapPinProps}
    >
      <MapGeoPinContent
        price={geoPin.price}
        isSelected={isSelected}
        destinationName={geoPin.geo.name}
      />
    </MapPin>
  );
}

export default MapGeoPin;
