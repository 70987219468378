import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import PrimaryButton from 'components/core/buttons/PrimaryButton';

export const FormWrapper = styled.div(
  sx2CssThemeFn({
    '.search-control-form-wrapper': {
      position: 'relative',
      '.fields': {
        bg: 'backgroundGray',
        gap: 2,
        maxHeight: 'calc(100vh - 60px * 2)',
        overflowY: 'auto',
        '.dropdown-wrapper, .filter-input-wrapper': {
          position: 'static',
        },
      },
      '.product-features-wrapper': {
        rowGap: '10px',
      },
      '.sub-amenities': {
        position: 'absolute',
        top: '5px',
        left: 'calc(250px + 10px)',
        maxHeight: 'calc(100vh - 24px * 2)',
        overflowY: 'auto',
        bg: 'backgroundGray',
        zIndex: 1,
        '.checkboxes-area-wrapper': {
          pl: 0,
          '.label': {
            py: '1px',
          },
        },
      },
      '.sub-amenities-close-input': {
        display: 'none',
      },
      '.absolute-dropdown': {
        top: 50,
        left: 'calc(250px + 5px)',
      },
      '.autocomplete-dropdown': {
        top: 0,
        left: 0,
      },
    },
  })
);

export const FormBackButton = styled(PrimaryButton)(
  sx2CssThemeFn({
    position: ['absolute', 'static'],
    left: '10px',
    top: '10px',
    width: ['fit-content', '100%'],
    p: '9px 10px',
    bg: 'secondary',
    color: 'white',
    fontSize: ['18px', '22px'],
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    '.icon': {
      ml: 0,
      transform: 'rotate(180deg)',
    },
  })
);
