import { useRef } from 'react';
import { PageType, TravelType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  GetSrlResultsQuery,
  SrlGroupResultFragment,
  useGetSrlResultsQuery,
} from 'graphql/srl/GetSRLResults.generated';
import { SrlEmptyResultFragment } from 'graphql/srl/SrlEmptyResult.generated';
import { useSrlCriteriaValues } from './useSrlDataToCriteria';

interface ISRLResult {
  data?: SrlGroupResultFragment | SrlEmptyResultFragment;
  travelType: TravelType;
  loading: boolean;
}

export function useSRLResult(
  handleOnCompleted?: (res: GetSrlResultsQuery, queryTime?: number) => void
): ISRLResult {
  const now = useRef(Date.now());
  const pageType = usePageType<PageType>();

  const { filters, searchControl, groupSorting } = useSrlCriteriaValues();

  const { data, loading } = useGetSrlResultsQuery({
    ssr: false,
    variables: {
      searchControl,
      filters,
      groupSorting,
      pageType,
    },
    onCompleted: completedData => {
      const then = Date.now();
      handleOnCompleted?.(completedData, then - now.current);
    },
  });

  return {
    data: data?.srl.search?.group,
    travelType: searchControl.travelType,
    loading,
  };
}
