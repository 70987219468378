import { Page } from '@hotelplan/graphql.types';
import { GeoPrefix } from 'components/domain/geo/Geo.types';
import { ProductItemFragment } from 'graphql/srl/SRLProductItem.generated';
import { SrlItem } from './SRL.types';

const MAX_PAGES_THRESHOLD = 5;
const EDGE_PAGES_THRESHOLD = 3;

const addNumbersFactory = (nums: number[], total: number) => {
  return ([start, end]: number[]): void => {
    for (let i = start; i <= end; i++) {
      if (i >= 1 && i <= total && nums.indexOf(i) === -1) {
        nums.push(i);
      }
    }
  };
};

export const getSrlPageNumbers = (current: number, total: number): number[] => {
  const nums = [];
  const addNumbers = addNumbersFactory(nums, total);
  if (total <= MAX_PAGES_THRESHOLD) {
    addNumbers([1, total]);
  } else {
    if (current <= EDGE_PAGES_THRESHOLD) {
      addNumbers([1, EDGE_PAGES_THRESHOLD]);
    } else if (current >= total - EDGE_PAGES_THRESHOLD + 1) {
      addNumbers([total - EDGE_PAGES_THRESHOLD + 1, total]);
    }
    addNumbers([1, 1]);
    addNumbers([total, total]);
    addNumbers([current - 1, current + 1]);
  }
  nums.sort((a, b) => a - b);
  return nums;
};

export const filterSrlItems = (items: SrlItem[]): ProductItemFragment[] => {
  return items.filter(
    item => item === null || item?.__typename === 'SrlProductItem'
  ) as Array<ProductItemFragment | null>;
};

export const sliceSrlItems = (
  items: ProductItemFragment[],
  page: Page | undefined,
  resultsLimit: number
): ProductItemFragment[] => {
  const endIndex = page
    ? Math.min(resultsLimit, page.resultsPerPage)
    : resultsLimit;
  return items.slice(0, endIndex);
};

export const isSingleAccordionSearchResults = (objectType: string) => {
  return [GeoPrefix.Continent, GeoPrefix.CountryGroup].includes(
    objectType as GeoPrefix
  );
};
