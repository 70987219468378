import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { GeoObject } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { openSearchMap } from 'components/domain/srl-map';
import SortingResultsField from './sorting/SortingResultsField';
import { SrlToolbarButton } from './SRL.common';
import SubGeoFilters from './SubGeoFilters';

const Wrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    pt: [3, 0],
    position: 'relative',
    minHeight: '30px',
    '.sorting': {
      flex: ['140px 0 0', '300px 0 0'],
      position: [null, 'absolute'],
      right: [null, '0px'],
    },
    '.map': {
      position: [null, 'absolute'],
      left: [null, '35%'],
    },
  })
);

interface ISrlProductsToolbarProps {
  activeGeoObject?: GeoObject;
  searchedObjects?: Array<GeoObject>;
}

const SrlProductsToolbar: React.FC<ISrlProductsToolbarProps> = ({
  activeGeoObject,
  searchedObjects,
}) => {
  const [t] = useTranslation('search');

  return (
    <>
      <Wrapper>
        <SubGeoFilters />
        <div className="map">
          <SrlToolbarButton
            icon={{ name: 'map-solid' }}
            onClick={() => openSearchMap({ activeGeoObject, searchedObjects })}
          >
            {t('map.button')}
          </SrlToolbarButton>
        </div>
        <div className="sorting">
          <SortingResultsField />
        </div>
      </Wrapper>
    </>
  );
};

export default SrlProductsToolbar;
