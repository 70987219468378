import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionMode,
} from '@hotelplan/components.common.accordion';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useSearchState } from '@hotelplan/libs.search-state';
import { useScroll } from 'components/core/scroll/useScroll';
import {
  Bd4AccordionSkeleton,
  Bd4TravelAccordionItem,
  useBd4Accordion,
} from 'components/domain/bd4';
import {
  listenCloseSearchMap,
  listenOpenSearchMap,
} from 'components/domain/srl-map';
import { SrlGeoItemFragment } from 'graphql/srl/SrlGeoItem.generated';
import { PreviewResultsProvider } from './preview/PreviewResultsContext';
import { SortingResultsProvider } from './sorting/SortingResultsContext';
import { ISrlState } from './SRL.types';
import SrlGeoItemTitle from './SrlGeoItemTitle';
import SrlProductsContainer from './SrlProductsContainer';
import SrlProductsToolbar from './SrlProductsToolbar';
import useDefaultAccordionItem from './useDefaultAccordionItem';
import SubGeoFiltersContextProvider from './useSubGeoFiltersContext';

export const SrlAccordionItem = styled(AccordionItem)(
  ({ theme }) => theme.srl.accordionItem
);

interface ISrlGroupAccordionsContainerProps {
  geoItems: SrlGeoItemFragment[];
}

const SrlGroupAccordionsContainer: React.FC<
  ISrlGroupAccordionsContainerProps
> = ({ geoItems }: ISrlGroupAccordionsContainerProps) => {
  const { state } = useSearchState<ISrlState>();
  const { defaultItem, defaultItemIndex, checkIsExpanded } =
    useDefaultAccordionItem(geoItems);

  const [isMapOpened, setIsMapOpened] = React.useState<boolean>(false);
  const [currentItem, setCurrentItem] =
    React.useState<SrlGeoItemFragment>(defaultItem);

  const { mobile } = useDeviceType();
  const { bd4AccordionName, bd4AccordionLoading } = useBd4Accordion();
  const scrollTo = useScroll();

  const isSingleAccordion = geoItems.length === 1;

  const groupsRef = useRef<{ [id: string]: HTMLDivElement }>({});
  const onOpenGroup = useCallback(
    (id: string) => {
      setTimeout(() => {
        scrollTo(groupsRef.current[id]);
      }, 0);
    },
    [scrollTo, groupsRef]
  );

  useEffect(() => {
    mobile && listenOpenSearchMap(() => setIsMapOpened(true));
  }, [mobile]);

  useEffect(() => {
    mobile && listenCloseSearchMap(() => setIsMapOpened(false));
  }, [mobile]);

  // When the map is open on mobile, we have to display header, so
  // scroll to accordion item allowed only after the map is closed
  useEffect(() => {
    if (!isMapOpened) {
      scrollTo(groupsRef.current[currentItem?.geoObject.id]);
    }
  }, [isMapOpened, scrollTo, groupsRef, currentItem?.geoObject.id]);

  // Add a dummy item to the beginning of the list to render the BD4 accordion
  const accordionItems: Array<SrlGeoItemFragment | { __typename: 'Bd4Item' }> =
    [{ __typename: 'Bd4Item' }, ...geoItems];

  return (
    <Accordion
      mode={AccordionMode.SINGLE_COLLAPSABLE}
      defaultActiveIndex={defaultItemIndex + 1} // +1 because of the dummy item
    >
      {accordionItems.map(item => {
        switch (item.__typename) {
          case 'Bd4Item':
            if (bd4AccordionLoading)
              return (
                <Bd4AccordionSkeleton key={`${item.__typename}-skeleton`} />
              );
            return (
              <Bd4TravelAccordionItem
                key={item.__typename}
                title={bd4AccordionName}
              />
            );
          case 'SrlGeoItem':
            return (
              <SrlAccordionItem
                key={item.geoObject.id}
                title={
                  <SrlGeoItemTitle
                    geoItem={item}
                    travelType={state.searchControl.travelType}
                    refCallback={node => {
                      groupsRef.current[item.geoObject.id] = node;
                    }}
                  />
                }
                customIcon={{ activeIcon: 'minus', inactiveIcon: 'plus' }}
                onOpen={() => {
                  onOpenGroup(item.geoObject.id);
                  setCurrentItem(item);
                }}
              >
                {active => (
                  <SortingResultsProvider>
                    <PreviewResultsProvider
                      isExpanded={
                        (active && checkIsExpanded(item)) || isSingleAccordion
                      }
                    >
                      <SubGeoFiltersContextProvider
                        defaultItems={item.geoSearchObjects}
                        activeGeoObject={item.geoObject}
                        isActive={active}
                      >
                        <SrlProductsToolbar
                          activeGeoObject={item.geoObject}
                          searchedObjects={item.geoSearchObjects}
                        />
                        <SrlProductsContainer
                          geoObject={item.geoObject}
                          geoSearchObjects={item.geoSearchObjects}
                          previewItems={item.previewItems}
                          active={active}
                        />
                      </SubGeoFiltersContextProvider>
                    </PreviewResultsProvider>
                  </SortingResultsProvider>
                )}
              </SrlAccordionItem>
            );
          default:
            return null;
        }
      })}
    </Accordion>
  );
};

export default SrlGroupAccordionsContainer;
