import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { Image } from '@hotelplan/components.common.image';
import { ImageMediaItem } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { formatPrice } from '@hotelplan/libs.utils';
import PrimaryButton from 'components/core/buttons/PrimaryButton';
import { useGetGeoMediaGalleryQuery } from 'graphql/geo/GetGeoMediaGallery.generated';
import {
  CloseCardButton,
  GeoCardImage,
  GeoCardTitle,
  GeoCardWrapper,
  GeoCheapestPrice,
} from './MapGeoCard.styled';
import { IMapGeoCardProps } from './MapGeoCard.types';

export default function MapGeoCard({
  selectedGeoObject,
  onCloseCard,
  onClick,
}: IMapGeoCardProps): React.ReactElement {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();
  const { price, geo } = selectedGeoObject;

  // TODO: add loading
  const { data, loading } = useGetGeoMediaGalleryQuery({
    variables: {
      id: geo.id,
    },
  });

  const geoImage = useMemo(
    () => (data?.geo.mediaGallery?.mediaItems[0] as ImageMediaItem)?.image,
    [data]
  );

  return (
    <GeoCardWrapper>
      {loading ? (
        // TODO: Add skeleton
        <h2>Loading....</h2>
      ) : (
        <>
          {geoImage ? (
            <GeoCardImage>
              <Image {...geoImage} />
            </GeoCardImage>
          ) : null}
          <div className={'card-info'}>
            <GeoCardTitle>{geo.name}</GeoCardTitle>
            <GeoCheapestPrice>
              <p className="title">{t('common:perPerson')}</p>
              <p>
                <span className="currency">
                  {t('common:priceFrom')}&nbsp;{price.currency}&nbsp;
                </span>
                <span className="price">
                  {formatPrice(price, { currency: false })}
                </span>
              </p>
            </GeoCheapestPrice>
          </div>
          <PrimaryButton onClick={onClick}>
            {t('common:btn.details')}
          </PrimaryButton>
          <CloseCardButton onClick={onCloseCard}>
            <Icon name="close" size={mobile ? 'sm' : 'md'} />
          </CloseCardButton>
        </>
      )}
    </GeoCardWrapper>
  );
}
