import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useSearchState } from '@hotelplan/libs.search-state';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { useResetRadiusSearch } from 'components/domain/srl-map';
import { SubGeoFilterItemFragment } from 'graphql/srl/GetSubGeoFiltersContent.generated';
import { usePreviewResultsContext } from './preview/PreviewResultsContext';
import { ISrlToolbarButtonProps, SrlToolbarButton } from './SRL.common';
import { ISrlState } from './SRL.types';
import SubGeoFiltersSkeleton from './SubGeoFiltersSkeleton.skeleton';
import { useSubGeoFiltersContext } from './useSubGeoFiltersContext';

const SubGeoFiltersContent = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: ['nowrap', 'wrap'],
    flexDirection: ['column', 'row'],
    maxWidth: ['300px', '100%'],
    minWidth: ['120px', '100%'],
    maxHeight: ['300px', '100%'],
    overflow: ['auto', 'unset'],
    pt: [null, 1],
  })
);

const FilterButton = styled(SrlToolbarButton).attrs<ISrlToolbarButtonProps>(
  ({ active }) => ({
    icon: {
      name: active ? 'minus' : 'plus',
      size: 'sm',
    },
  })
)(
  sx2CssThemeFn({
    borderLeft: 'none',
  })
);

const CheckboxWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    '&:not(:last-child)': {
      [media.mobile]: {
        borderBottom: '1px solid',
        borderColor: 'borderGray',
      },
    },
    '.label': {
      display: 'flex',
      flexDirection: ['row-reverse', 'row'],
      justifyContent: ['space-between', 'flex-start'],
      p: ['8px 5px', '5px'],
      mb: '0px',
      fontWeight: [null, 'bold'],
      fontSize: '14px',
      color: 'textGray',
      cursor: 'pointer',
      '.text': {
        color: 'textGray',
        fontSize: [null, 0],
        ml: 0,
      },

      '&:has(input:checked)': {
        background: ['backgroundGray', 'transparent'],
        color: ['primary', 'black'],
        fontWeight: 'bold',
        span: {
          color: ['primary', 'black'],
        },
      },
      '.customCheckboxInput, input:checked ~ .customCheckboxInput': {
        [media.mobile]: {
          borderColor: 'transparent',
          background: 'transparent',
          mr: 1,
        },
      },
      'input:checked ~ .customCheckboxInput:after': {
        [media.mobile]: {
          borderWidth: '0 3px 3px 0',
          width: '10px',
          height: '15px',
          top: '-1px',
        },
      },
    },
  })
);

const SubGeoFilterMobileWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: '100%',
    bg: 'white',
    border: '1px solid',
    borderColor: 'borderGray',
    zIndex: 10,
  })
);

const SubGeoFiltersWrapper = styled.div({
  position: 'relative',
});

export default function SubGeoFilters(): React.ReactElement {
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();
  const {
    state: { filters },
  } = useSearchState<ISrlState>();

  const { subGeoFilterItems, subGeoFilterValues, loading, onChange } =
    useSubGeoFiltersContext();
  const { onExpandResults } = usePreviewResultsContext();

  const [isOpened, , , toggle] = useToggleState(!!filters.radius);

  const onChangeGeoFilters = useCallback(
    (nextValue: string) => {
      onExpandResults();
      onChange(nextValue);
    },
    [onExpandResults, onChange]
  );

  function renderLoading(): React.ReactElement {
    return (
      <SubGeoFiltersWrapper>
        {mobile ? (
          <SubGeoFilterMobileWrapper>
            <SubGeoFiltersSkeleton />
          </SubGeoFilterMobileWrapper>
        ) : (
          <SubGeoFiltersSkeleton />
        )}
      </SubGeoFiltersWrapper>
    );
  }

  const subGeoFilters = (
    <SubGeoFiltersValues
      items={subGeoFilterItems}
      values={subGeoFilterValues}
      onChange={onChangeGeoFilters}
    />
  );

  if (loading) {
    return renderLoading();
  } else {
    if (!subGeoFilterItems?.length) return null;
  }

  return (
    <SubGeoFiltersWrapper>
      <FilterButton active={isOpened} onClick={toggle}>
        {t('subGeoFilters.button')}
      </FilterButton>
      {isOpened ? (
        <>
          {!mobile ? (
            <>{subGeoFilters}</>
          ) : (
            <SubGeoFilterMobileWrapper>
              {subGeoFilters}
            </SubGeoFilterMobileWrapper>
          )}
        </>
      ) : null}
    </SubGeoFiltersWrapper>
  );
}

interface ISubGeoFiltersValuesProps {
  items?: SubGeoFilterItemFragment[];
  values?: string[];
  onChange: (nextValue: string) => void;
}

function SubGeoFiltersValues({
  items,
  values,
  onChange,
}: ISubGeoFiltersValuesProps): React.ReactElement {
  const { mobile } = useDeviceType();
  const {
    state: { filters },
  } = useSearchState<ISrlState>();
  const resetRadiusSearch = useResetRadiusSearch();

  const totalProductsCount = useMemo(() => {
    if (filters.radius) {
      return items.reduce((acc, item) => {
        return acc + item.productCount;
      }, 0);
    }
  }, [items, filters.radius]);

  const radiusSearchItem = filters.radius ? (
    <CheckboxWrapper>
      <Checkbox
        value={filters.radius.label}
        checked={!!filters.radius}
        onChange={resetRadiusSearch}
        content={
          <span className="text">{`${filters.radius.radius}m ${filters.radius.label} (${totalProductsCount})`}</span>
        }
      />
    </CheckboxWrapper>
  ) : null;

  return (
    <SubGeoFiltersContent>
      {items?.map(item => {
        const checkboxContent = mobile
          ? item.caption
          : `${item.caption} (${item.productCount})`;

        return (
          <CheckboxWrapper key={item.id}>
            <Checkbox
              value={item.id}
              checked={values?.includes(item.id)}
              onChange={onChange}
              content={<span className="text">{checkboxContent}</span>}
            />
          </CheckboxWrapper>
        );
      })}
      {radiusSearchItem}
    </SubGeoFiltersContent>
  );
}
