import React, { useMemo } from 'react';
import { SrlGeoGroupItemFragment } from 'graphql/srl/SrlGeoGroupItem.generated';
import { SrlGeoItemFragment } from 'graphql/srl/SrlGeoItem.generated';
import SrlGroupAccordionsContainer from './SrlGroupAccordionsContainer';

interface ISrlSingleGroupContainerProps {
  group: SrlGeoGroupItemFragment;
}

const SrlSingleGroupContainer: React.FC<ISrlSingleGroupContainerProps> = (
  props: ISrlSingleGroupContainerProps
) => {
  const { group } = props;

  const groupItems = useMemo(() => {
    return group.items.filter(
      item => item.__typename === 'SrlGeoItem'
    ) as SrlGeoItemFragment[];
  }, [group.items]);

  return <SrlGroupAccordionsContainer geoItems={groupItems} />;
};

interface ISrlMultipleGroupsContainerProps {
  groups: SrlGeoGroupItemFragment[];
}

const SrlMultipleGroupsContainer: React.FC<ISrlMultipleGroupsContainerProps> = (
  props: ISrlMultipleGroupsContainerProps
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { groups } = props;
  // todo: check what's the case for multiple groups?
  //  can we just render them one by one or what's expected?
  return <div>SrlMultipleGroupContainer</div>;
};

interface ISrlGroupsProps {
  groups: SrlGeoGroupItemFragment[];
}

const SrlGroups: React.FC<ISrlGroupsProps> = (props: ISrlGroupsProps) => {
  const { groups } = props;

  return groups.length == 1 ? (
    <SrlSingleGroupContainer group={groups[0]} />
  ) : groups.length > 1 ? (
    <SrlMultipleGroupsContainer groups={groups} />
  ) : null;
};

export default SrlGroups;
