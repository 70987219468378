import React, { useMemo } from 'react';
import { MapProvider } from '@hotelplan/components.common.map';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { usePreventBodyScroll } from 'libs';
import useSRLMapConfig from './hooks/useSRLMapConfig';
import { ISearchMapProps } from './SRLMap.types';
import { MapProviderWrapper } from './SRLMapContainer.styled';
import SRLMapContent from './SRLMapContent';

export default function SearchMap({
  mapContainerRef,
  preventBodyScroll = true,
  ...mapContentProps
}: ISearchMapProps): React.ReactElement {
  const mapSize = useElementSize(mapContainerRef);
  const mapConfig = useSRLMapConfig();

  const mapStyles = useMemo(() => {
    const styles: React.CSSProperties = {
      position: 'absolute',
      width: `${mapSize.w}px`,
      height: `${mapSize.h}px`,
    };

    return styles;
  }, [mapSize.h, mapSize.w]);

  // Prevent body scrolling when map modal is opened
  usePreventBodyScroll(preventBodyScroll);

  return (
    <MapProviderWrapper>
      <MapProvider
        styles={mapStyles}
        zoom={mapConfig.zoom}
        options={mapConfig.options}
      >
        <SRLMapContent {...mapContentProps} />
      </MapProvider>
    </MapProviderWrapper>
  );
}
