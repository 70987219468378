import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { CustomSelectInput } from 'components/domain/custom-select-input';
import { usePreviewResultsContext } from 'components/domain/srl/preview/PreviewResultsContext';
import {
  ISrlAnySorting,
  useSortingResultsContext,
} from './SortingResultsContext';

interface ISortingResultsProps {
  className?: string;
}

const SortingResultsField: React.FC<ISortingResultsProps> = (
  props: ISortingResultsProps
) => {
  const { className } = props;
  const [t] = useTranslation('search');
  const { options, sorting, setSorting } = useSortingResultsContext();
  const { onExpandResults } = usePreviewResultsContext();
  const { mobile } = useDeviceType();

  const commonSelectProps = {
    label: !mobile && t('search:sorting.label'),
    className,
  };

  const onChangeSorting = useCallback(
    (nextValue: string) => {
      onExpandResults();
      setSorting(nextValue as ISrlAnySorting);
    },
    [onExpandResults, setSorting]
  );

  return (
    <CustomSelectInput
      options={options}
      onChange={onChangeSorting}
      value={sorting ?? ''}
      {...commonSelectProps}
    />
  );
};

export default SortingResultsField;
