import { useMemo } from 'react';
import { SrlGroupSorting } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { ISrlState } from './SRL.types';

export const useSrlCriteriaValues = () => {
  const { loading, state } = useSearchState<ISrlState>();

  return useMemo(() => {
    const { searchControl, filters } = state;

    return {
      searchControl: mapSearchControlToCriteria(searchControl),
      filters: mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
      groupSorting: SrlGroupSorting.GroupAlphabetic,
    };
  }, [loading, state]);
};
