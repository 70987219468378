import { track } from '@hotelplan/libs.tracking-events';

export const trackSRLLoadMoreButtonClick = (language: string) => {
  track({
    event: 'load_more',
    action: 'load more',
    label: 'Alle anzeigen',
    category: undefined,
    component: 'button',
    position: 'srl',
    language,
    _clear: true,
  });
};
