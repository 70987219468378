import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import ActionButtons from 'components/domain/search-control/components/SearchControlFormLayout/MobileSearchControlActionButtons';

export const RadiusSearchIcon = styled(Button).attrs({
  icon: { name: 'map-radius-search' },
})<{ opened?: boolean }>(
  ({ theme }) => ({
    '.icon': theme.icons.xlg,
  }),
  ({ opened }) =>
    sx2CssThemeFn({
      position: 'absolute',
      top: '10px',
      right: '10px',
      p: 2,
      backgroundColor: opened && 'secondary',
      backgroundImage: !opened && 'linear-gradient(#fff,#e7e7e7)',
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: 'default',
      cursor: 'pointer',
      color: opened ? 'white' : 'inherit',
    })
);

export const RadiusSearchMobileWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: 70,
    right: 10,
    width: 'fit-content',
    minWidth: '280px',
    height: '170px',
    p: 2,
    backgroundColor: 'white',
    boxShadow: '0 1px 7px rgba(0,0,0,0.5)',
  })
);

export const RadiusSearchDesktopWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    select: {
      backgroundImage: 'linear-gradient(#fff, #e7e7e7)',
      '&:hover': {
        background:
          'linear-gradient(rgb(253, 253, 253) 0px, rgb(208, 208, 208) 100%)',
      },
    },
  })
);

export const RadiusSearchControls = styled(ActionButtons)(
  sx2CssThemeFn({
    border: 'none',
    bg: 'transparent',
    p: 0,
    mt: 3,
  })
);

export const ResetButton = styled(Button)(
  sx2CssThemeFn({
    height: '40px',
    color: 'textGray',
    p: 2,
    backgroundImage: 'linear-gradient(#fff,#e7e7e7)',
    border: '1px solid',
    borderLeft: 'none',
    borderColor: 'borderGray',
    borderRadius: '0px 5px 5px 0px',
  })
);
